import React from 'react';
import styled from 'styled-components';
import '../../styles/components/testimonial-grid.scss';

import checkmark from '../assets/checkmark.svg';
import joe from '../assets/avatar5.png';
import michael from '../assets/avatar.png';
import { P1, H3 } from '../atoms/Text';
import QuoteAvatar from '../elements/QuoteAvatar';

const TestimonialGrid = ({ order = [0, 1, 2, 3] }) => {
  // const testimonials = [
  //   <li key="workvivo" style={{ backgroundImage: `url(${checkmark})` }}>
  //     <div className="inner">
  //       <P2>
  //         <b>Native Look and Feel</b> “Dropping the URL bar makes our UI look{' '}
  //         <strong>
  //           <em>much cleaner and less cluttered</em>
  //         </strong>
  //         . It makes it easier for our customers to keep the app open all day
  //         and not get lost in a sea of tabs.”
  //       </P2>
  //       <div className="meta">
  //         <div className="avatar" style={{ backgroundImage: `url(${joe})` }} />
  //         <div className="author">
  //           <div className="name">
  //             <P3>Joe Lennon</P3>
  //           </div>
  //           <div className="company">
  //             <P3>
  //               CTO of{' '}
  //               <a
  //                 href="https://www.workvivo.com/"
  //                 target="_blank"
  //                 rel="noreferrer noopener"
  //               >
  //                 Workvivo
  //               </a>
  //             </P3>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   </li>,
  //   <li key="carefolk" style={{ backgroundImage: `url(${checkmark})` }}>
  //     <div className="inner">
  //       <P2>
  //         <b>Save Developer Time</b> “I was going to build and code a desktop
  //         app manually (using Electron).
  //         <strong>
  //           <em> ToDesktop has just saved me a ton of time!</em>
  //         </strong>
  //         ”
  //       </P2>
  //       <div className="meta">
  //         <div className="avatar" style={{ backgroundImage: `url(${owen})` }} />
  //         <div className="author">
  //           <div className="name">
  //             <P3>Owen O'Doherty</P3>
  //           </div>
  //           <div className="company">
  //             <P3>
  //               CEO of{' '}
  //               <a
  //                 href="https://carefolk.com/"
  //                 target="_blank"
  //                 rel="noreferrer noopener"
  //               >
  //                 Carefolk
  //               </a>
  //             </P3>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   </li>
  // ];

  // return (
  //   <ul className="testimonial-grid">
  //     {order.map(index => testimonials[index])}
  //   </ul>
  // );
  return (
    <StyledGrid>
      <div className="testimonial">
        <div className="checkmark-heading">
          <div className="checkmark">
            <img src={checkmark} alt="checkmark" />
          </div>
          <H3>Increase active users</H3>
        </div>
        <P1>
          “Users who download our desktop app are <strong>more active</strong>{' '}
          than our existing users and we've{' '}
          <strong>reduced our churn rate</strong> as a result.”
        </P1>
        <div className="quote-avatar">
          <QuoteAvatar
            src={michael}
            name="Michael Fitzgerald"
            title="Co-Founder of"
            company="Interplay"
            companyURL="https://interplayapp.com/"
          />
        </div>
      </div>
      <div className="testimonial">
        <div className="checkmark-heading">
          <div className="checkmark">
            <img src={checkmark} alt="checkmark" />
          </div>
          <H3>Less cluttered</H3>
        </div>
        <P1>
          “Dropping the URL bar makes our UI look{' '}
          <strong>much cleaner and less cluttered</strong>. It makes it easier
          for our customers to keep the <strong>app open all day</strong> and
          not get lost in a sea of tabs.”
        </P1>
        <div className="quote-avatar">
          <QuoteAvatar
            src={joe}
            name="Joe Lennon"
            title="CTO of"
            company="Workvivo"
            companyURL="https://www.workvivo.com/"
          />
        </div>
      </div>
    </StyledGrid>
  );
};

const StyledGrid = styled.div`
  div {
    max-width: 56rem;
    & > p {
      margin-bottom: ${props => props.theme.space[6]};
    }

    h3 {
      font-weight: 600;
    }

    .checkmark-heading {
      margin-bottom: 1.6rem;
      display: flex;
      align-items: center;

      .checkmark {
        width: 2.6rem;
        margin-right: ${props => props.theme.space[5]};

        img {
          width: inherit;
        }
      }
    }

    .quote-avatar {
      margin-bottom: ${props => props.theme.space[9]};
    }
  }

  @media only screen and (max-width: ${props => props.theme.breakpoints[1]}) {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
  }
`;

export default TestimonialGrid;
