import React, { useState } from 'react';
import styled from 'styled-components';
import Container from '../../~reusables/layout/Container';
import { H4, P2, H2 } from '../../~reusables/atoms/Text';

const LandingTestimonial = ({ collection, testimonials }) => {
  const [itemIdx, setItemIdx] = useState(
    Math.floor((testimonials.length - 1) / 2)
  );

  const setCurrentAvatar = idx => {
    setItemIdx(idx);
  };

  return (
    <StyledLandingTestimonial>
      <Container className="testimonial-wrapper">
        <div className="avatars">
          {testimonials.slice(0, 5).map((testimonial, idx) => {
            return (
              <div
                onClick={() => setItemIdx(idx)}
                className={`quote-wrapper display-${itemIdx === idx}`}
                key={idx}
              >
                {collection === 'ycLeads' ? (
                  <H2 className="batch fade">{testimonial.batch}</H2>
                ) : (
                    ''
                  )}
                <div
                  className="quote"
                  dangerouslySetInnerHTML={{ __html: testimonial.quote }}
                />
                <H2 className="statistic fade">{testimonial.statistic}</H2>
              </div>
            );
          })}
          <div className="images-container">
            {testimonials.slice(0, 5).map((testimonial, idx) => (
              <div
                key={idx}
                onClick={() => setCurrentAvatar(idx)}
                className={`display-${itemIdx === idx} distance-${Math.ceil(
                  Math.sqrt(Math.pow(idx - itemIdx, 2))
                )}`}
              >
                <img src={testimonial.avatar} alt="avatar of person" />
              </div>
            ))}
          </div>
          {testimonials.slice(0, 5).map((testimonial, idx) => {
            return (
              <div key={idx} className={`display-${itemIdx === idx}`}>
                <div className="author">
                  <H4>{testimonial.name}</H4>
                  <P2>
                    {testimonial.role}{' '}
                    <a
                      rel="noopener noreferrer"
                      target="_blank"
                      href={testimonial.companyUrl}
                    >
                      {testimonial.company}
                    </a>
                    {collection === 'ycLeads' ? ` (${testimonial.batch})` : ''}
                  </P2>
                </div>
              </div>
            );
          })}
        </div>
      </Container>
    </StyledLandingTestimonial>
  );
};

const StyledLandingTestimonial = styled.section`
  background: linear-gradient(180deg, #ffffff 50%, #f6f9fd 80.39%);
  padding: ${props => props.theme.space[9]} 0;
  overflow-x: hidden;

  .testimonial-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    .quote {
      line-height: ${props => props.theme.lineHeights.title};
      margin: ${props => props.theme.space[8]} 0;
      z-index: 1;

      p,
      strong {
        font-size: ${props => props.theme.fontSizes[6]};
        font-style: italic;
        color: ${props => props.theme.colors.text};
      }
    }

    .quote-wrapper {
      position: relative;
      max-width: 66%;

      .fade {
        position: absolute;
        font-size: 8rem;
        color: ${props => props.theme.colors.grey};
        opacity: 25%;
      }

      .batch {
        left: -12rem;
        top: -2.8rem;
      }

      .statistic {
        right: -12rem;
        bottom: -2.8rem;
      }
    }

    .distance-0 {
      height: 64px;
      width: 64px;
    }

    .distance-1,
    .distance-4 {
      height: 48px;
      width: 48px;
      opacity: 0.5;
    }

    .distance-2,
    .distance-3 {
      height: 32px;
      width: 32px;
      opacity: 0.25;
    }

    .distance-0 img,
    .distance-1 img,
    .distance-2 img,
    .distance-3 img,
    .distance-4 img {
      height: 100%;
      width: 100%;
      cursor: pointer;
    }

    .display-false .quote,
    .display-false .author,
    .display-false .statistic {
      display: none;
    }

    .display-true {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .avatars {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .images-container {
        display: flex;
        justify-content: center;
        align-items: center;

        & > div {
          margin: ${props => props.theme.space[5]};
        }
      }
    }

    .author {
      h4 {
        font-weight: 600;
      }

      p {
        color: ${props => props.theme.colors.greys[4]};
        font-weight: 500;
      }
    }
  }

  @media only screen and (max-width: ${props => props.theme.breakpoints[1]}) {
    .testimonial-wrapper {
      .quote {
        p,
        strong {
          font-size: 2.6rem;
        }
      }

      .quote-wrapper {
        max-width: 85%;

        .fade {
          font-size: 6rem;
        }

        .batch {
          left: -4rem;
          top: -1.8rem;
        }

        .statistic {
          right: -4rem;
          bottom: -1.8rem;
        }
      }
    }
  }

  @media only screen and (max-width: ${props => props.theme.breakpoints[0]}) {
    padding: ${props => props.theme.space[8]} 0;
    .testimonial-wrapper {
      .distance-0 {
        height: 48px;
        width: 48px;
      }

      .distance-1,
      .distance-4 {
        height: 36px;
        width: 36px;
        opacity: 0.5;
      }

      .distance-2,
      .distance-3 {
        height: 24px;
        width: 24px;
        opacity: 0.25;
      }

      .quote {
        p,
        strong {
          font-size: 2.2rem;
        }
      }

      .quote-wrapper {
        max-width: 90%;

        .fade {
          font-size: 5rem;
        }

        .batch {
          left: -2.4rem;
          top: -0.8rem;
        }

        .statistic {
          right: -2.4rem;
          bottom: -0.8rem;
        }
      }
    }
  }
`;

export default LandingTestimonial;
