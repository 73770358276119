import React from 'react';
import styled from 'styled-components';
import { H2, P1 } from '../../~reusables/atoms/Text';
import ProfileAvatar from '../../~reusables/elements/ProfileAvatar';

import chatWithTheTeam from '../../~reusables/assets/chat-with-the-team.jpg';
import isaacAderogba from '../../~reusables/assets/isaacAderogba.jpg';
import daveJeffery from '../../~reusables/assets/daveJeffery.jpg';
import adamLynch from '../../~reusables/assets/adamLynch.png';
import { TertiaryButton, SecondaryButton } from '../../~reusables/atoms/Button';
import { buildWithAppUrl } from '../../actions/buildWithAppUrl';
import CTAWithInput from '../../~reusables/molecules/CTAWithInput';
import { eventTracking } from '../../~reusables/constants/eventTracking';

const LandingReassurance = ({ cta, lead, track }) => {
  const { appUrl, companyName, customIcon, autoIcon } = lead;
  return (
    <StyledLandingReassurance>
      <div className="chat-image-container">
        <img src={chatWithTheTeam} alt="2 people having a conversation" />
      </div>
      <div className="desktop-expertise">
        <div className="profile-avatar-containers">
          <ProfileAvatar
            src={daveJeffery}
            name="Dave Jeffery"
            role="CEO and Co-Founder"
          />
          <ProfileAvatar
            src={adamLynch}
            name="Adam Lynch"
            role="CTO and Co-Founder"
          />
          <ProfileAvatar
            src={isaacAderogba}
            name="Isaac Aderogba"
            role="CPO and Co-Founder"
          />
        </div>
        <H2 white>Dedicated desktop expertise each step of the way</H2>
        <P1 white>
          Our group of desktop experts work with you and are on call for any and
          all desktop related questions - from building and customizing to
          distribution and retention. We've seen it all.
        </P1>
        {cta === 'build-app' ? (
          appUrl ? (
            <SecondaryButton
              className="reassurance-cta"
              onClick={() => {
                track(eventTracking.submittedURLForm, {
                  url: appUrl,
                  host: 'leads',
                  source: 'build your app cta',
                  location: 'above footer'
                });
                buildWithAppUrl(appUrl, companyName, customIcon, autoIcon);
              }}
            >
              Build {companyName} Now
            </SecondaryButton>
          ) : (
            <div className="cta-with-input">
              <CTAWithInput lead={lead} track={track} location="above footer" />
            </div>
          )
        ) : cta === 'intercom' ? (
          <SecondaryButton className="reassurance-cta js-intercom">
            Request demo
          </SecondaryButton>
        ) : (
          ''
        )}
        <TertiaryButton
          onClick={() => track(eventTracking.chatWithTeam)}
          className="chat-cta js-intercom"
        >
          Chat with the team
        </TertiaryButton>
      </div>
    </StyledLandingReassurance>
  );
};

const StyledLandingReassurance = styled.section`
  background: linear-gradient(167.63deg, #009eff 16.73%, #0242e4 67.74%);
  display: flex;

  .cta-with-input {
    margin-bottom: ${props => props.theme.space[7]};
  }

  .chat-image-container {
    min-height: 100%;
    width: 40%;

    img {
      min-height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  .desktop-expertise {
    .profile-avatar-containers {
      display: flex;
      flex-wrap: wrap;
    }

    padding: ${props => props.theme.space[10]} 5%;
    max-width: 680px;

    & > h2,
    & > p {
      margin: ${props => props.theme.space[7]} 0;
    }

    .chat-cta {
      font-size: ${props => props.theme.fontSizes[4]};
      margin-bottom: ${props => props.theme.space[7]};
    }

    .reassurance-cta {
      margin-right: ${props => props.theme.space[6]};
      font-size: ${props => props.theme.fontSizes[4]};
      margin-bottom: ${props => props.theme.space[7]};
    }
  }

  @media only screen and (max-width: ${props => props.theme.breakpoints[1]}) {
    flex-direction: column;

    .chat-image-container {
      width: 100%;
      height: 240px;

      img {
        height: inherit;
      }
    }

    .desktop-expertise {
      .profile-avatar-containers {
        display: flex;
        flex-wrap: wrap;
      }

      padding: ${props => props.theme.space[8]} 5%;

      button {
        font-size: ${props => props.theme.fontSizes[3]};
      }
    }
  }
`;

export default LandingReassurance;
