import React, { useState } from 'react';
import styled from 'styled-components';
import LandingNavbar from './LandingNavbar';
import Container from '../../~reusables/layout/Container';
import { H2, P1 } from '../../~reusables/atoms/Text';
import Mac from '../../~reusables/molecules/Mac';
import Windows from '../../~reusables/molecules/Windows';
import OSPreviewSwitcher from '../../~reusables/molecules/OSPreviewSwitcher';
import { PrimaryButton } from '../../~reusables/atoms/Button';
import { eventTracking } from '../../~reusables/constants/eventTracking';
import { buildWithAppUrl } from '../../actions/buildWithAppUrl';
import CTAWithInput from '../../~reusables/molecules/CTAWithInput';

const LandingHeader = ({ cta, lead, track }) => {
  const { appUrl, companyName, customIcon, autoIcon } = lead;
  const [device, setDevice] = useState('mac');

  return (
    <StyledLandingHeader device={device}>
      <LandingNavbar cta={cta} lead={lead} track={track} />
      <Container className="hero-header">
        <section className="hero-text">
          <div>
            <H2 white>
              Turn {lead.companyName} into a desktop app with no coding.
            </H2>
            <P1 white>
              We build <strong>Windows</strong>, <strong>Mac</strong> and{' '}
              <strong>Linux</strong> apps that you can give to your customers
              with a click.
            </P1>
          </div>
          <div className="hero-form">
            {cta === 'build-app' ? (
              appUrl ? (
                <PrimaryButton
                  className="primary-button"
                  onClick={() => {
                    track(eventTracking.submittedURLForm, {
                      url: appUrl,
                      host: 'leads',
                      source: 'build your app cta',
                      location: 'navbar'
                    });
                    buildWithAppUrl(appUrl, companyName, customIcon, autoIcon);
                  }}
                >
                  Build {lead.companyName} Now
                </PrimaryButton>
              ) : (
                <div className="cta-with-input">
                  <CTAWithInput
                    noShadow
                    lead={lead}
                    track={track}
                    location="header"
                  />
                </div>
              )
            ) : cta === 'intercom' ? (
              <PrimaryButton className="primary-button js-intercom">
                Request demo
              </PrimaryButton>
            ) : cta === 'download' && lead.desktopAppUniversalLink ? (
              <p
                style={{
                  background:
                    'linear-gradient(113.02deg, #009eff 0%, #0242e4 101.17%)',
                  display: 'inline-block',
                  color: '#fff',
                  'text-align': 'center',
                  'border-radius': '5px'
                }}
              >
                <a
                  class="primary-button"
                  download
                  href={lead.desktopAppUniversalLink}
                  style={{
                    display: 'block',
                    'border-radius': '5px',
                    'text-decoration': 'none'
                  }}
                >
                  Download
                  <span
                    style={{
                      display: 'block',
                      opacity: 0.8,
                      'margin-top': '0.5rem'
                    }}
                  >
                    {lead.companyName} desktop app
                  </span>
                </a>
              </p>
            ) : cta === 'cli' ? null : (
              ''
            )}
          </div>
        </section>
        <section className="hero-image">
          <div className="mac-display">
            <Mac lead={lead} device={device} />
          </div>
          <div className="windows-display">
            <Windows lead={lead} device={device} />
          </div>

          <OSPreviewSwitcher lead={lead} track={track} callback={setDevice} />
        </section>
      </Container>
    </StyledLandingHeader>
  );
};

const StyledLandingHeader = styled.header`
  background: url(${require('../../~reusables/assets/curved-gradient.svg')});
  background-repeat: no-repeat;
  background-size: 100% 450px;

  .hero-header {
    display: flex;
    padding-top: ${props => props.theme.space[9]};
    justify-content: space-evenly;

    .hero-text {
      width: 40%;
      max-width: 42rem;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;

      & > div {
        margin-bottom: ${props => props.theme.space[5]};
      }

      h2,
      p {
        margin-bottom: ${props => props.theme.space[7]};
      }

      .hero-form {
        width: 100%;
        padding-bottom: ${props => props.theme.space[9]};

        .primary-button {
          font-size: 2.2rem;
          padding: ${props =>
            `${props.theme.space[6]} ${props.theme.space[8]}`};
        }

        p {
          margin-bottom: 0;
        }
      }
    }

    .hero-image {
      width: 60%;

      .mac-display {
        display: ${props => (props.device === 'mac' ? 'block' : 'none')};
      }

      .windows-display {
        display: ${props => (props.device === 'windows' ? 'block' : 'none')};
      }
    }
  }

  @media only screen and (max-width: ${props => props.theme.breakpoints[1]}) {
    .hero-header {
      padding-top: ${props => props.theme.space[7]};
      flex-direction: column;
      .hero-text {
        margin: 0 auto;
        max-width: none;
        width: 100%;
        text-align: center;

        .hero-form {
          display: flex;
          justify-content: center;
          padding-bottom: ${props => props.theme.space[7]};

          p {
            color: ${props => props.theme.colors.greys[9]};
          }

          .primary-button {
            background: white;
            color: ${props => props.theme.colors.primary};
            box-shadow: ${props => props.theme.shadows.shallow};
            -webkit-box-shadow: ${props => props.theme.shadows.shallow};
            -moz-box-shadow: ${props => props.theme.shadows.shallow};
            font-size: 2rem;
          }
        }
      }

      .hero-image {
        width: 100%;
      }
    }
  }

  @media only screen and (max-width: ${props => props.theme.breakpoints[0]}) {
    .hero-header .hero-text {
      margin-bottom: ${props => props.theme.space[4]};
      & > div {
        margin-bottom: ${props => props.theme.space[3]};
      }

      h2,
      p {
        margin-bottom: ${props => props.theme.space[6]};
      }
    }
  }
`;

export default LandingHeader;
