import React from 'react';
import styled from 'styled-components';

const CLIAccessForm = () => {
  return (
    <StyledCLIAccessForm>
      <form
        className="request-early-access-btn"
        method="post"
        action="https://www.formstack.com/forms/index.php"
      >
        <input type="hidden" name="form" value="3773203" />
        <input type="hidden" name="viewkey" value="Vh6bEFbpX5" />
        <input
          type="email"
          name="email"
          required
          placeholder="email@example.com"
        />
        <button type="submit">Request early access</button>
      </form>
    </StyledCLIAccessForm>
  );
};

const StyledCLIAccessForm = styled.div`
  .request-early-access-btn {
    display: flex;
    justify-content: center;
    margin: 32px auto 24px auto;

    @media screen and (max-width: ${props => props.theme.breakpoints[1]}) {
      flex-direction: column;
      max-width: 460px;
      padding: 0 16px;
    }

    & > input {
      font-size: 17px;
      transition: all 100ms ease-in-out;
      min-height: 40px;
      min-width: 280px;
      border: 1px solid #a0aec0;
      border-radius: 4px;
      padding-left: 12px;
      color: #1a202c;
      margin-right: 16px;
      font-weight: 500;
      box-sizing: border-box;

      &:hover,
      &:focus {
        border-color: #006df6;
        outline: 0 none;
      }

      &::placeholder {
        color: #a0aec0;
        opacity: 1;
      }

      &:-ms-input-placeholder {
        color: #a0aec0;
      }

      &::-ms-input-placeholder {
        color: #a0aec0;
      }

      @media screen and (max-width: ${props => props.theme.breakpoints[1]}) {
        width: 100%;
        min-width: 0;
        margin-right: 0;
        margin-bottom: 16px;
      }
    }

    & > button {
      background: linear-gradient(115.55deg, #009eff 0%, #0242e4 101.17%);
      box-shadow: 0px 6.9px 8.05px rgba(168, 183, 203, 0.09),
        0px 5.75px 17.25px rgba(168, 183, 203, 0.07),
        0px 16.1px 21.85px rgba(168, 183, 203, 0.07);
      cursor: pointer;
      font-weight: 500;
      padding: 12px 16px;
      border-radius: 4px;
      color: white;
      font-size: 17px;

      &:hover {
        opacity: 0.9;
      }

      &:active {
        opacity: 0.8;
      }

      @media screen and (max-width: ${props => props.theme.breakpoints[1]}) {
        width: 100%;
      }
    }
  }
`;

export default CLIAccessForm;
