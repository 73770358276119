import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import HelpTip from '../elements/HelpTip';
import { H2 } from '../atoms/Text';
import pixelDots from '../assets/pixel-dots.png';

import '../../styles/components/cost-calculator.scss';
import { eventTracking } from '../constants/eventTracking';

const createAnalyticsInputs = properties =>
  Object.entries(properties).map(([key, value]) => (
    <input key={key} type="hidden" name={`analytics-${key}`} value={value} />
  ));

const CostCalculator = ({
  defaultValues = {
    initialWeeks: 10,
    autoUpdateWeeks: 3,
    monthlyDays: 6,
    devRate: 66,
    qaWeeks: 2
  },
  track,
  analyticsProperties = {}
}) => {
  const [trackCalculator, setTrackCalculator] = useState({
    createElectronAppHrs: false,
    createUpdateServerHrs: false,
    maintenanceHours: false,
    hourlyDeveloperRate: false,
    qaWeeks: false
  });

  const dailyWorkHours = 10;
  const weeklyWorkHours = dailyWorkHours * 5;

  useEffect(() => {
    let hasInteractedWithCostCalculator = false;

    const costCalculators = [...document.querySelectorAll('.cost-calculator')];

    costCalculators.forEach(form => {
      const getInput = el => el.querySelector('input');
      const getVal = el => Number(getInput(el).value);
      const getOutput = el => el.querySelector('.number');
      const setOutput = (el, val) => (getOutput(el).innerText = val);

      const initialWeeksEl = form.querySelector('#cost-initial-hours');
      const autoUpdateWeeksEl = form.querySelector('#cost-auto-update-hours');
      const monthlyDaysEl = form.querySelector('#cost-monthly-hours');
      const qaWeeksEl = form.querySelector('#cost-qa-rate');

      [initialWeeksEl, autoUpdateWeeksEl, monthlyDaysEl, qaWeeksEl].forEach(
        el => {
          const input = getInput(el);
          input.addEventListener('change', calcCosts);
          input.addEventListener('input', calcCosts);
        }
      );

      function numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      }

      function calcCosts() {
        const totalEl = document.querySelector('.cost-total');
        // const costWithTD = document.querySelector('#cost-with-todesktop');

        const initialWeeks = getVal(initialWeeksEl);
        const autoUpdateWeeks = getVal(autoUpdateWeeksEl);
        const monthlyDays = getVal(monthlyDaysEl);
        const qaWeeks = getVal(qaWeeksEl);

        setOutput(initialWeeksEl, initialWeeks);
        setOutput(autoUpdateWeeksEl, autoUpdateWeeks);
        setOutput(monthlyDaysEl, monthlyDays);
        setOutput(qaWeeksEl, qaWeeks);

        const annualMonthlyDays = monthlyDays * dailyWorkHours * 12;
        const convertedWeeklyHours = initialWeeks * weeklyWorkHours;
        const convertedAutoUpdateWeeks = autoUpdateWeeks * weeklyWorkHours;
        const convertedQAWeeks = qaWeeks * weeklyWorkHours;
        const total =
          (convertedWeeklyHours +
            convertedAutoUpdateWeeks +
            convertedQAWeeks +
            annualMonthlyDays) /
          10;

        setOutput(totalEl, numberWithCommas(total));
        // setOutput(
        //   costWithTD,
        //   `$9588 (${100 - ((9588 / total) * 100).toFixed(2)}% saved)`
        // );

        if (!hasInteractedWithCostCalculator) {
          track(eventTracking.usedCostCalculator, {
            path: window.location.pathname
          });

          hasInteractedWithCostCalculator = true;
        }
      }
    });
  }, [track, weeklyWorkHours]);

  return (
    <StlyedCalcWrapper>
      <div>
        <img
          src={pixelDots}
          alt="dotted pixels"
          className="dotted-pixels top-corner-pixels"
        />
      </div>
      {/* <Fade bottom> */}
      <div className="developer-time">
        <H2>
          How many days would it take to build <b>in-house without ToDesktop</b>
          ?
        </H2>

        <form className="cost-calculator">
          {createAnalyticsInputs(analyticsProperties)}
          <div className="cost-row" id="cost-initial-hours">
            <div className="cost-title">
              <label htmlFor="cost-initial-hours-input">
                Creating a production quality desktop app
                <HelpTip>
                  Includes designing and developing the initial desktop
                  applications for Windows, Mac and Linux.
                </HelpTip>
              </label>
            </div>
            <div className="cost-slider">
              <input
                id="cost-initial-hours-input"
                type="range"
                min="1"
                max="52"
                onChange={() => {
                  if (!trackCalculator.createElectronAppHrs) {
                    setTrackCalculator({
                      ...trackCalculator,
                      createElectronAppHrs: true
                    });
                    track(eventTracking.usedCostCalculator, {
                      slider: 'createElectronAppHrs'
                    });
                  }
                }}
                defaultValue={defaultValues.initialWeeks}
              />
            </div>
            <div className="cost-number">
              <span className="number">{defaultValues.initialWeeks}</span>{' '}
              <small>weeks</small>
            </div>
          </div>

          <div className="cost-row" id="cost-auto-update-hours">
            <div className="cost-title">
              <label htmlFor="cost-auto-update-hours-input">
                Deployment and auto-update infrastructure
                <HelpTip>
                  The auto-update server pushes updates of your desktop app to
                  your users so your app is not vulnerable to security exploits.
                  Includes setting-up infrastructure to code-sign your desktop
                  application.
                </HelpTip>
              </label>
            </div>
            <div className="cost-slider">
              <input
                id="cost-auto-update-hours-input"
                type="range"
                min="1"
                max="25"
                onChange={() => {
                  if (!trackCalculator.createUpdateServerHrs) {
                    setTrackCalculator({
                      ...trackCalculator,
                      createUpdateServerHrs: true
                    });
                    track(eventTracking.usedCostCalculator, {
                      slider: 'createUpdateServerHrs'
                    });
                  }
                }}
                defaultValue={defaultValues.autoUpdateWeeks}
              />
            </div>
            <div className="cost-number">
              <span className="number">{defaultValues.autoUpdateWeeks}</span>{' '}
              <small>weeks</small>
            </div>
          </div>

          <div className="cost-row" id="cost-qa-rate">
            <div className="cost-title">
              <label htmlFor="cost-qa-rate-input">
                Quality assurance and security
                <HelpTip>
                  Includes quality assurance, testing and application security
                  on Windows, Mac and Linux.
                </HelpTip>
              </label>
            </div>
            <div className="cost-slider">
              <input
                id="cost-qa-rate-input"
                type="range"
                min="1"
                max="25"
                onChange={() => {
                  if (!trackCalculator.qaWeeks) {
                    setTrackCalculator({
                      ...trackCalculator,
                      qaWeeks: true
                    });
                    track(eventTracking.usedCostCalculator, {
                      slider: 'qaWeeks'
                    });
                  }
                }}
                defaultValue={defaultValues.qaWeeks}
              />
            </div>
            <div className="cost-number">
              <span className="number">{defaultValues.qaWeeks}</span>
              <small> weeks</small>
            </div>
          </div>

          <div className="cost-row" id="cost-monthly-hours">
            <div className="cost-title">
              <label htmlFor="cost-monthly-hours-input">
                Monthly maintenance
                <HelpTip>
                  How long it takes to update to new versions of Electron and
                  keep your desktop app fresh, keeping abridge of
                  Electron/Chromium security issues and also updating Electron
                  version, testing, publishing, rolling out via auto-update.
                </HelpTip>
              </label>
            </div>
            <div className="cost-slider">
              <input
                id="cost-monthly-hours-input"
                type="range"
                min="1"
                max="30"
                defaultValue={defaultValues.monthlyDays}
                onChange={() => {
                  if (!trackCalculator.maintenanceHours) {
                    setTrackCalculator({
                      ...trackCalculator,
                      maintenanceHours: true
                    });
                    track(eventTracking.usedCostCalculator, {
                      slider: 'maintenanceHours'
                    });
                  }
                }}
              />
            </div>
            <div className="cost-number">
              <span className="number">{defaultValues.monthlyDays}</span>{' '}
              <small>days/month</small>
            </div>
          </div>
          <div className="cost-row cost-total">
            <div className="cost-title full">
              <label>
                Time spent{' '}
                <span style={{ textDecoration: 'underline' }}>without</span>{' '}
                ToDesktop
              </label>
            </div>
            <div className="cost-number">
              <span className="number">
                {(
                  (defaultValues.initialWeeks * weeklyWorkHours +
                    defaultValues.autoUpdateWeeks * weeklyWorkHours +
                    defaultValues.qaWeeks * weeklyWorkHours +
                    defaultValues.monthlyDays * dailyWorkHours * 12) /
                  10
                )
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              </span>{' '}
              days
            </div>
          </div>
        </form>
      </div>
      {/* </Fade> */}
      <div>
        <img
          src={pixelDots}
          className="dotted-pixels bottom-corner-pixels"
          alt="dotted pixels"
        />
      </div>
    </StlyedCalcWrapper>
  );
};

const StlyedCalcWrapper = styled.div`
  max-width: 90%;
  margin: 0 auto;
  position: relative;

  #cost-with-todesktop {
    label,
    span,
    div {
      font-size: 1.8rem;
      padding: ${props => props.theme.space[4]} 0;
    }
  }

  .developer-time {
    & > h2,
    & > p {
      margin-bottom: ${props => props.theme.space[6]};
    }
  }

  .dotted-pixels {
    position: absolute;
  }
  .bottom-corner-pixels {
    right: -5rem;
    top: -3rem;
  }

  .top-corner-pixels {
    left: -5rem;
    bottom: -3rem;
  }

  .cost-total {
    padding: ${props => props.theme.space[6]} 0;

    label,
    span,
    div {
      font-size: 2.4rem;
      line-height: 1.25;
    }
  }

  @media only screen and (max-width: ${props => props.theme.breakpoints[0]}) {
    max-width: 100%;

    .cost-calculator div,
    .cost-calculator label {
      font-size: 1.4rem;
    }

    .cost-total {
      padding: ${props => props.theme.space[5]} 0;

      label,
      span,
      div {
        font-size: 1.6rem;
      }
    }
  }
`;

export default CostCalculator;

// In case we decide to revert
// import React, { useEffect, useState } from 'react';
// import styled from 'styled-components';
// import HelpTip from '../elements/HelpTip';
// import { H2, P2 } from '../atoms/Text';
// import pixelDots from '../assets/pixel-dots.png';

// import '../../styles/components/cost-calculator.scss';
// import { eventTracking } from '../constants/eventTracking';

// const createAnalyticsInputs = properties =>
//   Object.entries(properties).map(([key, value]) => (
//     <input key={key} type="hidden" name={`analytics-${key}`} value={value} />
//   ));

// const CostCalculator = ({
//   defaultValues = {
//     initialWeeks: 10,
//     autoUpdateWeeks: 3,
//     monthlyDays: 6,
//     devRate: 66,
//     qaWeeks: 2
//   },
//   track,
//   analyticsProperties = {}
// }) => {
//   const [trackCalculator, setTrackCalculator] = useState({
//     createElectronAppHrs: false,
//     createUpdateServerHrs: false,
//     maintenanceHours: false,
//     hourlyDeveloperRate: false,
//     qaWeeks: false
//   });

//   const dailyWorkHours = 10;
//   const weeklyWorkHours = dailyWorkHours * 5;

//   useEffect(() => {
//     let hasInteractedWithCostCalculator = false;

//     const costCalculators = [...document.querySelectorAll('.cost-calculator')];

//     costCalculators.forEach(form => {
//       const getInput = el => el.querySelector('input');
//       const getVal = el => Number(getInput(el).value);
//       const getOutput = el => el.querySelector('.number');
//       const setOutput = (el, val) => (getOutput(el).innerText = val);

//       const initialWeeksEl = form.querySelector('#cost-initial-hours');
//       const autoUpdateWeeksEl = form.querySelector('#cost-auto-update-hours');
//       const monthlyDaysEl = form.querySelector('#cost-monthly-hours');
//       const qaWeeksEl = form.querySelector('#cost-qa-rate');

//       [initialWeeksEl, autoUpdateWeeksEl, monthlyDaysEl, qaWeeksEl].forEach(
//         el => {
//           const input = getInput(el);
//           input.addEventListener('change', calcCosts);
//           input.addEventListener('input', calcCosts);
//         }
//       );

//       function numberWithCommas(x) {
//         return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
//       }

//       function calcCosts() {
//         const totalEl = document.querySelector('.cost-total');
//         const costWithTD = document.querySelector('#cost-with-todesktop');

//         const initialWeeks = getVal(initialWeeksEl);
//         const autoUpdateWeeks = getVal(autoUpdateWeeksEl);
//         const monthlyDays = getVal(monthlyDaysEl);
//         const qaWeeks = getVal(qaWeeksEl);

//         setOutput(initialWeeksEl, initialWeeks);
//         setOutput(autoUpdateWeeksEl, autoUpdateWeeks);
//         setOutput(monthlyDaysEl, monthlyDays);
//         setOutput(qaWeeksEl, qaWeeks);

//         const annualMonthlyDays = monthlyDays * dailyWorkHours * 12;
//         const convertedWeeklyHours = initialWeeks * weeklyWorkHours;
//         const convertedAutoUpdateWeeks = autoUpdateWeeks * weeklyWorkHours;
//         const convertedQAWeeks = qaWeeks * weeklyWorkHours;
//         const codeSignCost = 4798;
//         const total =
//           codeSignCost +
//           defaultValues.devRate *
//             (convertedWeeklyHours +
//               convertedAutoUpdateWeeks +
//               convertedQAWeeks +
//               annualMonthlyDays);

//         setOutput(totalEl, numberWithCommas(total));
//         setOutput(
//           costWithTD,
//           `$9588 (${100 - ((9588 / total) * 100).toFixed(2)}% saved)`
//         );

//         if (!hasInteractedWithCostCalculator) {
//           track(eventTracking.usedCostCalculator, {
//             path: window.location.pathname
//           });

//           hasInteractedWithCostCalculator = true;
//         }
//       }
//     });
//   }, [track]);

//   return (
//     <StlyedCalcWrapper>
//       <div>
//         <img
//           src={pixelDots}
//           alt="dotted pixels"
//           className="dotted-pixels top-corner-pixels"
//         />
//       </div>
//       {/* <Fade bottom> */}
//       <div className="developer-time">
//         <H2>
//           How much would it cost to build <b>in-house without ToDesktop</b>?
//         </H2>

//         <P2>
//           Developer time is expensive. Adjust the sliders below to
//           estimate the first year cost of a desktop app <b>without</b>{' '}
//           ToDesktop.
//         </P2>
//         <form className="cost-calculator">
//           {createAnalyticsInputs(analyticsProperties)}
//           <div className="cost-row" id="cost-initial-hours">
//             <div className="cost-title">
//               <label htmlFor="cost-initial-hours-input">
//                 Creating a production quality desktop app
//                 <HelpTip>
//                   Includes designing and developing the initial desktop
//                   applications for Windows, Mac and Linux.
//                 </HelpTip>
//               </label>
//             </div>
//             <div className="cost-slider">
//               <input
//                 id="cost-initial-hours-input"
//                 type="range"
//                 min="1"
//                 max="52"
//                 onChange={() => {
//                   if (!trackCalculator.createElectronAppHrs) {
//                     setTrackCalculator({
//                       ...trackCalculator,
//                       createElectronAppHrs: true
//                     });
//                     track(eventTracking.usedCostCalculator, {
//                       slider: 'createElectronAppHrs'
//                     });
//                   }
//                 }}
//                 defaultValue={defaultValues.initialWeeks}
//               />
//             </div>
//             <div className="cost-number">
//               <span className="number">{defaultValues.initialWeeks}</span>{' '}
//               <small>weeks</small>
//             </div>
//           </div>

//           <div className="cost-row" id="cost-auto-update-hours">
//             <div className="cost-title">
//               <label htmlFor="cost-auto-update-hours-input">
//                 Deployment and auto-update infrastructure
//                 <HelpTip>
//                   The auto-update server pushes updates of your desktop app to
//                   your users so your app is not vulnerable to security exploits.
//                   Includes setting-up infrastructure to code-sign your desktop
//                   application.
//                 </HelpTip>
//               </label>
//             </div>
//             <div className="cost-slider">
//               <input
//                 id="cost-auto-update-hours-input"
//                 type="range"
//                 min="1"
//                 max="25"
//                 onChange={() => {
//                   if (!trackCalculator.createUpdateServerHrs) {
//                     setTrackCalculator({
//                       ...trackCalculator,
//                       createUpdateServerHrs: true
//                     });
//                     track(eventTracking.usedCostCalculator, {
//                       slider: 'createUpdateServerHrs'
//                     });
//                   }
//                 }}
//                 defaultValue={defaultValues.autoUpdateWeeks}
//               />
//             </div>
//             <div className="cost-number">
//               <span className="number">{defaultValues.autoUpdateWeeks}</span>{' '}
//               <small>weeks</small>
//             </div>
//           </div>

//           <div className="cost-row" id="cost-qa-rate">
//             <div className="cost-title">
//               <label htmlFor="cost-qa-rate-input">
//                 Quality assurance and security
//                 <HelpTip>
//                   Includes quality assurance, testing and application security
//                   on Windows, Mac and Linux.
//                 </HelpTip>
//               </label>
//             </div>
//             <div className="cost-slider">
//               <input
//                 id="cost-qa-rate-input"
//                 type="range"
//                 min="1"
//                 max="25"
//                 onChange={() => {
//                   if (!trackCalculator.qaWeeks) {
//                     setTrackCalculator({
//                       ...trackCalculator,
//                       qaWeeks: true
//                     });
//                     track(eventTracking.usedCostCalculator, {
//                       slider: 'qaWeeks'
//                     });
//                   }
//                 }}
//                 defaultValue={defaultValues.qaWeeks}
//               />
//             </div>
//             <div className="cost-number">
//               <span className="number">{defaultValues.qaWeeks}</span>
//               <small> weeks</small>
//             </div>
//           </div>

//           <div className="cost-row" id="cost-monthly-hours">
//             <div className="cost-title">
//               <label htmlFor="cost-monthly-hours-input">
//                 Monthly maintenance
//                 <HelpTip>
//                   How long it takes to update to new versions of Electron and
//                   keep your desktop app fresh, keeping abridge of
//                   Electron/Chromium security issues and also updating Electron
//                   version, testing, publishing, rolling out via auto-update.
//                 </HelpTip>
//               </label>
//             </div>
//             <div className="cost-slider">
//               <input
//                 id="cost-monthly-hours-input"
//                 type="range"
//                 min="0"
//                 max="30"
//                 defaultValue={defaultValues.monthlyDays}
//                 onChange={() => {
//                   if (!trackCalculator.maintenanceHours) {
//                     setTrackCalculator({
//                       ...trackCalculator,
//                       maintenanceHours: true
//                     });
//                     track(eventTracking.usedCostCalculator, {
//                       slider: 'maintenanceHours'
//                     });
//                   }
//                 }}
//               />
//             </div>
//             <div className="cost-number">
//               <span className="number">{defaultValues.monthlyDays}</span>{' '}
//               <small>days/month</small>
//             </div>
//           </div>
//           <div className="cost-row" id="cost-code-sign">
//             <div className="cost-title">
//               <label>
//                 Ongoing costs (monitoring, code signing, etc.)
//                 <HelpTip>
//                   Includes time and money required to setup monitoring/analytics
//                   and code signing. Code signing certificates are required to
//                   prove your code is secure, reputable, and safe for your users
//                   to download.
//                 </HelpTip>
//               </label>
//             </div>
//             <div className="cost-number">
//               $<span className="number">4798</span>
//               <small>/year</small>
//             </div>
//           </div>

//           <div className="cost-row cost-total">
//             <div className="cost-title full">
//               <label>
//                 First year cost{' '}
//                 <span style={{ textDecoration: 'underline' }}>without</span>{' '}
//                 ToDesktop
//               </label>
//             </div>
//             <div className="cost-number">
//               $
//               <span className="number">
//                 {(
//                   (defaultValues.initialWeeks * weeklyWorkHours +
//                     defaultValues.autoUpdateWeeks * weeklyWorkHours +
//                     defaultValues.qaWeeks * weeklyWorkHours +
//                     defaultValues.monthlyDays * dailyWorkHours * 12) *
//                     defaultValues.devRate +
//                   4798
//                 )
//                   .toString()
//                   .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
//               </span>
//             </div>
//           </div>
//           <div className="cost-row" id="cost-with-todesktop">
//             <div className="cost-title">
//               <label>
//                 <strong>
//                   <em>
//                     First year cost <span>with</span> ToDesktop
//                   </em>
//                 </strong>
//               </label>
//             </div>
//             <div className="cost-number">
//               <em>
//                 <strong>
//                   <span className="number">
//                     $9588 (
//                     {(
//                       100 -
//                       (9588 /
//                         ((defaultValues.initialWeeks * weeklyWorkHours +
//                           defaultValues.autoUpdateWeeks * weeklyWorkHours +
//                           defaultValues.qaWeeks * weeklyWorkHours +
//                           defaultValues.monthlyDays * dailyWorkHours * 12) *
//                           defaultValues.devRate +
//                           4798)) *
//                         100
//                     ).toFixed(2)}
//                     % saved)
//                   </span>
//                 </strong>
//               </em>
//             </div>
//           </div>
//         </form>
//       </div>
//       {/* </Fade> */}
//       <div>
//         <img
//           src={pixelDots}
//           className="dotted-pixels bottom-corner-pixels"
//           alt="dotted pixels"
//         />
//       </div>
//     </StlyedCalcWrapper>
//   );
// };

// const StlyedCalcWrapper = styled.div`
//   max-width: 90%;
//   margin: 0 auto;
//   position: relative;

//   #cost-with-todesktop {
//     label,
//     span,
//     div {
//       font-size: 1.8rem;
//       padding: ${props => props.theme.space[4]} 0;
//     }
//   }

//   .developer-time {
//     & > h2,
//     & > p {
//       margin-bottom: ${props => props.theme.space[6]};
//     }
//   }

//   .dotted-pixels {
//     position: absolute;
//   }
//   .bottom-corner-pixels {
//     right: -5rem;
//     top: -3rem;
//   }

//   .top-corner-pixels {
//     left: -5rem;
//     bottom: -3rem;
//   }

//   .cost-total {
//     padding: ${props => props.theme.space[6]} 0;

//     label,
//     span,
//     div {
//       font-size: 2.4rem;
//       line-height: 1.25;
//     }
//   }

//   @media only screen and (max-width: ${props => props.theme.breakpoints[0]}) {
//     max-width: 100%;

//     .cost-calculator div,
//     .cost-calculator label {
//       font-size: 1.4rem;
//     }

//     .cost-total {
//       padding: ${props => props.theme.space[5]} 0;

//       label,
//       span,
//       div {
//         font-size: 1.6rem;
//       }
//     }
//   }
// `;

// export default CostCalculator;
