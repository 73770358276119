import React, { useEffect, useRef, useState, useLayoutEffect } from 'react'
import styled from 'styled-components'
import { Window, TitleBar } from 'react-desktop/macOs'

const S = styled.div`
  position: relative;

  .mac-mockup {
    max-width: 100%;
  }

  .window {
    border-radius: 5px;
    position: absolute;
    left: 20px;
    top: 10px;
    width: 698px !important;
    /* width = 738 [screen width] - (20*2) [padding from screen edge] */
    height: 360px !important;
    animation-delay: 0.75s;
  }
  .loaded .window {
    animation: scale-in-center 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both 0.6s;
  }

  .screenshot {
    width: 100%;
    height: 100%;
    background-position: center top;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 0 0 4px 4px;
  }

  @keyframes scale-in-center {
    0% {
      transform: scale(0);
      opacity: 1;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }

  @keyframes bounce {
    0%,
    15%,
    47%,
    73%,
    89%,
    100% {
      animation-timing-function: ease-out;
      transform: translate3d(0, 0, 0);
    }

    30%,
    32% {
      animation-timing-function: ease-in;
      transform: translate3d(0, -42px, 0);
    }

    60% {
      animation-timing-function: ease-in;
      transform: translate3d(0, -24px, 0);
    }

    82% {
      animation-timing-function: ease-in;
      transform: translate3d(0, -12px, 0);
    }

    94% {
      animation-timing-function: ease-in;
      transform: translate3d(0, -3px, 0);
    }
  }

  .icon {
    position: absolute;
    left: 50%;
    max-height: 45px;
    width: 45px;
    bottom: 11px;
    margin-left: -22.5px;
    transform-origin: center bottom;
    animation-duration: 1.5s;
    animation-fill-mode: both;
  }
  .loaded .icon {
    animation-name: bounce;
  }
`

const macbookImageWidth = 1040
const macbookScreenWidth = 738
const macbookScreenHeight = 461

const calcWindowLeft = (scale = 1) => {
  const paddingFromScreenEdge = 20
  return (
    ((macbookImageWidth - macbookScreenWidth) / 2 + paddingFromScreenEdge) *
    scale
  )
};

const calcWindowTop = (scale = 1) => {
  const paddingFromImageEdge = 50
  return paddingFromImageEdge * scale
};

const Mac = ({ lead, device }) => {
  const macbookRef = useRef()

  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const [fauxAppScale, setFauxAppScale] = useState(1)
  const [fauxAppLeft, setFauxAppLeft] = useState(calcWindowLeft())
  const [fauxAppTop, setFauxAppTop] = useState(calcWindowTop())

  const [hasMacbookImageLoaded, setHasMacbookImageLoaded] = useState(false)

  const updateWindowWidth = () => {
    setWindowWidth(window.innerWidth)
  };

  useEffect(() => {
    window.addEventListener('resize', updateWindowWidth)
    return () => window.removeEventListener('resize', updateWindowWidth)
  })

  useLayoutEffect(() => {
    if (macbookRef.current) {
      const macDimensions = macbookRef.current.getBoundingClientRect()

      const macbookCurrentWidth = macDimensions.width
      const scaleRatio = macbookCurrentWidth / macbookImageWidth

      setFauxAppScale(scaleRatio)
      setFauxAppLeft(calcWindowLeft(scaleRatio))
      setFauxAppTop(calcWindowTop(scaleRatio))
    }
  }, [macbookRef, hasMacbookImageLoaded, windowWidth, device])
  return (
    <S>
      <img
        className='mac-mockup'
        src={require('../../images/mac-mockup-min.png')}
        alt='Macbook mockup'
        ref={macbookRef}
        onLoad={() => {
          setHasMacbookImageLoaded(true)
        }}
      />

      <div
        style={{
          position: 'absolute',
          transform: `scale(${fauxAppScale})`,
          transformOrigin: '0 0',
          top: fauxAppTop * 0.65,
          left: fauxAppLeft * 0.88,
          width: macbookScreenWidth,
          height: macbookScreenHeight
        }}
        className={hasMacbookImageLoaded ? 'loaded' : ''}
      >
        <Window
          chrome
          className='window'
          padding='0'
          isWindowFocused
        >
          <TitleBar
            title={lead.companyName || lead.name}
            controls
            isWindowFocused
          />
          <div
            className='screenshot'
            style={{
              backgroundImage: `url('${lead.customScreenshot ||
                lead.autoScreenshot}')`
            }}
          />
        </Window>

        <img
          className='icon'
          src={lead.customIcon || lead.autoIcon}
          alt={lead.companyName || lead.name}
        />
      </div>
    </S>
  )
};

export default Mac
