import React, { useEffect, useRef, useState, useLayoutEffect } from 'react';
import styled from 'styled-components';
import { Window, TitleBar } from 'react-desktop/windows';

const S = styled.div`
  position: relative;

  .windows-mockup {
    max-width: 100%;
  }

  .window {
    position: absolute;
    left: 95px;
    top: 10px;
    width: 638px !important;
    height: 405px !important;
    animation-delay: 0.75s;
  }

  .loaded .window {
    animation: scale-in-center 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both
      0.4s;
  }

  .screenshot {
    width: 100%;
    height: 100%;
    background-position: center top;
    background-repeat: no-repeat;
    background-size: cover;
  }

  @keyframes scale-in-center {
    0% {
      transform: scale(0);
      opacity: 1;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }

  @keyframes bounce {
    0%,
    15%,
    47%,
    73%,
    89%,
    100% {
      animation-timing-function: ease-out;
      transform: translate3d(0, 0, 0);
    }

    30%,
    32% {
      animation-timing-function: ease-in;
      transform: translate3d(0, -42px, 0);
    }

    60% {
      animation-timing-function: ease-in;
      transform: translate3d(0, -24px, 0);
    }

    82% {
      animation-timing-function: ease-in;
      transform: translate3d(0, -12px, 0);
    }

    94% {
      animation-timing-function: ease-in;
      transform: translate3d(0, -3px, 0);
    }
  }

  .icon-wrap {
    position: absolute;
    left: 205px;
    height: 31px;
    width: 33px;
    bottom: 0;
    background: rgba(255, 255, 255, 0.08);
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 2px solid #86dcfe;

    &:hover {
      background: rgba(255, 255, 255, 0.2);
    }
  }

  .icon {
    height: 27px;
    width: 27px;
    transform-origin: center bottom;
    animation-duration: 1.5s;
    animation-fill-mode: both;
  }

  .desktop-icons {
    padding-left: 10px;
    padding-top: 10px;
  }

  .desktop-icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 70px;
    margin-bottom: 20px;

    img {
      width: 40px;
      margin-bottom: 5px;
    }

    &:first-child {
      img {
        width: 30px;
      }
    }

    span {
      font-size: 11px;
      font-weight: 500;
      display: inline-block;
      font-family: 'Segoe UI', Frutiger, 'Frutiger Linotype', 'Dejavu Sans',
        'Helvetica Neue', Arial, sans-serif;
      color: white;
      text-shadow: rgba(0, 0, 0, 0.6) 0px 1px 2px;
      text-overflow: ellipsis;
    }
  }
`;

const windowsImageWidth = 1040;
const windowsScreenWidth = 738;
const windowsScreenHeight = 461;

const calcWindowLeft = (scale = 1) => {
  const paddingFromScreenEdge = 20;
  return (
    ((windowsImageWidth - windowsScreenWidth) / 2 + paddingFromScreenEdge) *
    scale
  );
};

const calcWindowTop = (scale = 1) => {
  const paddingFromImageEdge = 50;
  return paddingFromImageEdge * scale;
};

const Windows = ({ lead, device }) => {
  const windowsRef = useRef();

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [fauxAppScale, setFauxAppScale] = useState(1);
  const [fauxAppLeft, setFauxAppLeft] = useState(calcWindowLeft());
  const [fauxAppTop, setFauxAppTop] = useState(calcWindowTop());

  const [hasWindowsbookImageLoaded, setHasWindowsbookImageLoaded] = useState(
    false
  );

  const updateWindowWidth = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', updateWindowWidth);
    return () => window.removeEventListener('resize', updateWindowWidth);
  });

  useLayoutEffect(() => {
    if (windowsRef.current) {
      const windowsDimensions = windowsRef.current.getBoundingClientRect();

      const windowsCurrentWidth = windowsDimensions.width;
      const scaleRatio = windowsCurrentWidth / windowsImageWidth;

      setFauxAppScale(scaleRatio);
      setFauxAppLeft(calcWindowLeft(scaleRatio));
      setFauxAppTop(calcWindowTop(scaleRatio));
    }
  }, [windowsRef, hasWindowsbookImageLoaded, windowWidth, device]);
  return (
    <S>
      <img
        className="windows-mockup"
        src={require('../../images/windows-mockup-min.png')}
        alt="Windows mockup"
        ref={windowsRef}
        onLoad={() => {
          setHasWindowsbookImageLoaded(true);
        }}
      />

      <div
        style={{
          position: 'absolute',
          transform: `scale(${fauxAppScale})`,
          transformOrigin: '0 0',
          top: fauxAppTop * 0.65,
          left: fauxAppLeft * 0.88,
          width: windowsScreenWidth,
          height: windowsScreenHeight
        }}
        className={hasWindowsbookImageLoaded ? 'loaded' : ''}
      >
        <Window chrome className="window" padding="0" isWindowFocused>
          <TitleBar
            title={lead.companyName || lead.name}
            controls
            isWindowFocused
          />
          <div
            className="screenshot"
            style={{
              backgroundImage: `url('${lead.customScreenshot ||
                lead.autoScreenshot}')`
            }}
          />
        </Window>

        <div className="desktop-icons">
          <div className="desktop-icon">
            <img
              src={require('../../images/recycle-bin.png')}
              alt="Recycle Bin"
            />
            <span>Recycle Bin</span>
          </div>
          <div className="desktop-icon">
            <img
              src={lead.customIcon || lead.autoIcon}
              alt={lead.companyName || lead.name}
            />
            <span>{lead.companyName || lead.name}</span>
          </div>
        </div>

        <div className="icon-wrap">
          <img
            className="icon"
            src={lead.customIcon || lead.autoIcon}
            alt={lead.companyName || lead.name}
          />
        </div>
      </div>
    </S>
  );
};

export default Windows;
