import React from 'react';
import styled from 'styled-components';
import Container from '../../~reusables/layout/Container';
import TestimonialGrid from '../../~reusables/molecules/TestimonialGrid';
import OneClick from '../../~reusables/molecules/OneClick';
import { H1 } from '../../~reusables/atoms/Text';

const LandingObjections = ({ lead }) => {
  return (
    <StyledLandingObjections>
      <Container className="landing-objections">
        <div className="text">
          <H1>Your Icon on the Desktop</H1>
          {/* <P1>
            With ToDesktop your users can install <strong>your</strong> web app
            (or website) to their computer and launch it from the same place as
            other desktop apps on <strong>Windows</strong>
            {', '}
            <strong>Mac</strong>
            {' and '}
            <strong>Linux</strong>.
          </P1> */}
          <TestimonialGrid />
        </div>
        <div className="one-click">
          <OneClick lead={lead} />
        </div>
      </Container>
    </StyledLandingObjections>
  );
};

const StyledLandingObjections = styled.section`
  background: ${props => props.theme.colors.lightBackground};
  padding: ${props => props.theme.space[9]} 0;

  .landing-objections {
    display: flex;
    justify-content: space-evenly;

    .text {
      width: 55%;

      & > h1,
      & > p {
        margin-bottom: ${props => props.theme.space[8]};
      }
    }

    .one-click {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  @media only screen and (max-width: ${props => props.theme.breakpoints[1]}) {
    .landing-objections {
      flex-direction: column;

      .text {
        width: 100%;
      }
    }
  }

  @media only screen and (max-width: ${props => props.theme.breakpoints[0]}) {
    padding: ${props => props.theme.space[8]} 0;
  }
`;

export default LandingObjections;
