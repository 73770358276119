import React from 'react';
import styled from 'styled-components';
import { P2, H5 } from '../atoms/Text';

const ProfileAvatar = ({ src, name, role }) => {
  return (
    <StyledProfileAvatar>
      <div className="image-container">
        <img src={src} alt={name} />
      </div>
      <div className="name-and-role">
        <H5 white>{name}</H5>
        <P2 white>{role}</P2>
      </div>
    </StyledProfileAvatar>
  );
};

const StyledProfileAvatar = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: ${props => props.theme.space[7]};
  margin-right: ${props => props.theme.space[7]};

  .image-container {
    width: 48px;
    height: 48px;
    border-radius: 100%;

    img {
      width: inherit;
      height: inherit;
      border-radius: inherit;
      object-fit: cover;
    }
  }

  .name-and-role {
    margin-left: ${props => props.theme.space[6]};
  }
`;

export default ProfileAvatar;
