import React from 'react';
import styled from 'styled-components';
import Container from '../../~reusables/layout/Container';
import footerLogo from '../../~reusables/assets/footer-logo.svg';
import { FaTwitter } from 'react-icons/fa';

import { P2, H5 } from '../../~reusables/atoms/Text';
import { eventTracking } from '../../~reusables/constants/eventTracking';

const LandingFooter = ({ track }) => {
  return (
    <StyledLandingFooter>
      <Container className="landing-footer">
        <div className="company-information">
          <div className="footer-logo">
            <img src={footerLogo} alt="ToDesktop Logo" />
          </div>
          <P2>
            All of the tools you need to grow your desktop business, end-to-end.
            <br />
          </P2>
          <span>© 2020 ToDesktop</span>
        </div>
        <div className="links">
          <div className="link-column">
            <H5>ToDesktop</H5>
            <ul>
              <li
                onClick={() =>
                  track(eventTracking.clickedFooterLink, {
                    link: 'Terms'
                  })
                }
              >
                <a href="https://www.todesktop.com/terms">Terms</a>
              </li>
              <li
                onClick={() =>
                  track(eventTracking.clickedFooterLink, {
                    link: 'Privacy Policy'
                  })
                }
              >
                <a href="https://www.todesktop.com/privacy-policy">
                  Privacy Policy
                </a>
              </li>
            </ul>
          </div>
          <div className="link-column">
            <H5>Links</H5>
            <ul>
              <li
                onClick={() =>
                  track(eventTracking.clickedFooterLink, {
                    link: 'Roadmap'
                  })
                }
              >
                <a href="https://roadmap.todesktop.com/">Roadmap</a>
              </li>
              <li
                onClick={() =>
                  track(eventTracking.clickedFooterLink, {
                    link: 'Documentation'
                  })
                }
              >
                <a href="https://docs.todesktop.com/">Documentation</a>
              </li>
              <li
                onClick={() =>
                  track(eventTracking.clickedFooterLink, {
                    link: 'Compare with Electron'
                  })
                }
              >
                <a href="https://www.todesktop.com/electron-alternative">
                  Compare with Electron
                </a>
              </li>
              <li
                onClick={() =>
                  track(eventTracking.clickedFooterLink, {
                    link: 'Integrations'
                  })
                }
              >
                <a href="https://www.todesktop.com/works-with">Integrations</a>
              </li>
            </ul>
          </div>
          <div className="link-column">
            <H5>Get In Touch</H5>
            <ul>
              <li
                onClick={() =>
                  track(eventTracking.clickedFooterLink, {
                    link: 'Contact email'
                  })
                }
              >
                <a href="mailto:hi@todesktop.com">hi@todesktop.com</a>
              </li>
              <li
                onClick={() =>
                  track(eventTracking.clickedFooterLink, {
                    link: 'Twitter'
                  })
                }
              >
                <a href="https://twitter.com/ToDesktop">
                  <FaTwitter />
                  @ToDesktop
                </a>
              </li>
              <li
                onClick={() =>
                  track(eventTracking.clickedFooterLink, {
                    link: 'Live chat with us'
                  })
                }
              >
                <button className="js-intercom">
                  Live chat with us
                </button>
              </li>
            </ul>
          </div>
        </div>
      </Container>
    </StyledLandingFooter>
  );
};

const StyledLandingFooter = styled.section`
  background: ${props => props.theme.colors.lightBackground};
  padding: ${props => props.theme.space[9]} 0;

  .landing-footer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .company-information {
      flex: 1;
      min-width: 240px;
      max-width: 320px;
      margin-bottom: ${props => props.theme.space[8]};
      margin-right: ${props => props.theme.space[8]};

      span {
        color: ${props => props.theme.colors.greys[4]};
      }

      .footer-logo,
      p {
        margin-bottom: ${props => props.theme.space[5]};
      }
    }

    .links {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      flex: 4;
      max-width: 720px;
      margin: 0 auto;
      .link-column {
        margin-bottom: ${props => props.theme.space[8]};
        padding: 0 ${props => props.theme.space[5]};

        button {
          border: 0;
          background: none;
          color: #738aa5;
          font-size: 1.6rem;
          cursor: pointer;
          outline: none;
          padding: 0;
        }
      }

      ul li {
        color: ${props => props.theme.colors.greys[4]};
        margin-top: ${props => props.theme.space[6]};
        a {
          text-decoration: none;
        }
      }
    }
  }

  @media only screen and (max-width: ${props => props.theme.breakpoints[0]}) {
    padding: ${props => props.theme.space[8]} 0;
  }
`;

export default LandingFooter;
