import React from 'react';
import styled from 'styled-components';
import { P2 } from '../atoms/Text';

const QuoteAvatar = ({ src, name, title, company, companyURL }) => {
  return (
    <StyledQuoteAvatar>
      <div className="avatar-image">
        <img src={src} alt={name} />
      </div>
      <div className="author">
        <div className="name">
          <P2>{name}</P2>
        </div>
        <div className="company">
          <P2>
            {title}{' '}
            <a href={companyURL} target="_blank" rel="noreferrer noopener">
              {company}
            </a>
          </P2>
        </div>
      </div>
    </StyledQuoteAvatar>
  );
};

const StyledQuoteAvatar = styled.div`
  display: flex;
  align-items: center;

  .author {
    margin-left: ${props => props.theme.space[5]};

    .name {
      p {
        margin-bottom: 0;
      }
    }

    .company {
      p {
        margin-bottom: 0;
      }
    }
  }

  .avatar-image {
    max-width: 72px;
    width: 72px;

    img {
      max-width: inherit;
      width: inherit;
    }
  }
`;

export default QuoteAvatar;
