import React from 'react';
import styled, { keyframes } from 'styled-components';
import Container from '../../~reusables/layout/Container';
import { H3, P2 } from '../../~reusables/atoms/Text';

const LandingFeatures = ({ lead }) => {
  return (
    <StyledLandingFeatures
      style={
        lead.shouldHideLandingCTASection
          ? {
              'padding-top': '1rem'
            }
          : {}
      }
    >
      <Container className="landing-features">
        <div className="menubar-cvps">
          <div className="menubar-cvp">
            <div className="feature-icon-box">
              <div
                style={{
                  color: '#fff',
                  lineHeight: '12px',
                  position: 'absolute',
                  fontWeight: 'bold'
                }}
              >
                <div className="line-1">1010101101001000101010</div>
                <div className="line-2">0111010110100101110001</div>
                <div className="line-3">1010101101001000101010</div>
              </div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="34.924 351.353 548.489 159.86"
                className="signature"
              >
                <path
                  fill="none"
                  stroke="#006DF6"
                  strokeWidth="8.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M107.581,428.161c-10.663-3.553-25.783,30.933-12.163,31.759c9.906,0.412,10.873-18.834,13.134-26.911 c-5.65,11.058,3.828,48.292,20.075,6.995c-11.509,10.832,17.602,55.514,27.12-39.959c-2.612,27.794-5.091,45.328,6.053,56.885 c9.493,9.906,24.097,5.309,26.899-6.503c9.349-39.39,2.886-89.151-4.863-88.502c-7.76,0.648-12.929,44.588-4.217,79.835 c2.759,11.161,9.906,22.289,23.114,24.354c14.859,2.475,13.208-12.385,17.335-21.466c1.238,5.367,1.238,11.971,7.842,12.795 c7.429,1.238,9.492-9.906,14.446-9.906c2.063,0,4.127,4.129,6.192,4.541c3.302,1.238,3.715-0.412,7.017-1.651 c19.812-6.19,17.879-0.059,38.517-0.059"
                />
              </svg>
            </div>
            <H3>Code signing</H3>
            <P2>
              We sign your desktop app with EV Microsoft Authenticode and Apple
              Gatekeeper certificates. Code signing is{' '}
              <strong>super-important</strong> because your app downloads and
              installs without warning dialogs.
            </P2>
          </div>
          <div className="menubar-cvp">
            <div className="feature-icon-box">
              <svg
                width="42px"
                height="42px"
                viewBox="0 0 80 100"
                version="1.1"
              >
                <g
                  stroke="none"
                  strokeWidth="1"
                  fill="none"
                  fillRule="evenodd"
                  transform="translate(28, 22)"
                >
                  <g id="bolt" stroke="#8ABAFB" fill="#006DF6" strokeWidth="3">
                    <path
                      d="M23.5245175,31.4860533 C23.2948665,31.0743489 22.8577467,30.817196 22.3840915,30.817196 L17.2874049,30.817196 L28.7673457,14.3736952 C29.0439707,13.9762771 29.0765916,13.4593737 28.8508551,13.0320843 C28.6264234,12.603496 28.1801698,12.3346544 27.6947711,12.3346544 L22.3397271,12.3346544 L26.7461558,1.83943837 C26.9144796,1.43812396 26.8701152,0.980963211 26.6274158,0.618611366 C26.3847164,0.257558273 25.9789127,0.0406666667 25.5417928,0.0406666667 L9.48448987,0.0406666667 C8.95211708,0.0406666667 8.47324253,0.362757196 8.27360273,0.853685502 L0.675546888,19.6336413 C0.513747313,20.0349558 0.562026219,20.4882202 0.804725581,20.8466758 C1.04872978,21.2038327 1.45322872,21.4181268 1.88643403,21.4181268 L7.01574152,21.4181268 L0.667717876,37.9681252 C0.515052149,38.3681409 0.56985523,38.8149116 0.812554593,39.1681722 C1.05655879,39.520134 1.45714322,39.7305318 1.88643403,39.7305318 L8.17965459,39.7305318 L8.17965459,52.7154557 C8.17965459,53.2946991 8.56588583,53.8051087 9.12566017,53.9635565 C9.24570501,53.997324 9.36574986,54.0142078 9.48448987,54.0142078 C9.92682903,54.0142078 10.3495957,53.7895237 10.592295,53.4024956 L23.4918966,32.8055855 C23.742425,32.4029723 23.7554733,31.8990565 23.5245175,31.4860533 L23.5245175,31.4860533 L23.5245175,31.4860533 Z"
                      id="Shape"
                    ></path>
                  </g>
                </g>
              </svg>
            </div>
            <H3>Auto updates</H3>
            <P2>
              Your app will regularly auto-update to ensure the underlying
              browser is always up to date. Plus, this is how we deliver
              performance improvements, security patches, and additional
              features to your app.
            </P2>
          </div>
          <div className="menubar-cvp">
            <div className="feature-icon-box">
              <div className="progress-container">
                <div className="progress-inner"></div>
              </div>
            </div>
            <H3>Native installers</H3>
            <P2>
              You'll get a customised installer for <strong>Windows</strong>,{' '}
              <strong>Mac</strong> and <strong>Linux</strong>. We even provide a
              magic link you can send your users which will detect their
              operating system and download the most up to date version of your
              app.
            </P2>
          </div>
        </div>
      </Container>
    </StyledLandingFeatures>
  );
};

const fadeInLeft = keyframes`
0% {
      transform: translate3d(-150%, 0, 0);
    }

    20% {
      transform: translate3d(0, 0, 0);
    }
`;

const fadeInRight = keyframes`
0% {
      transform: translate3d(150%, 0, 0);
    }

    20% {
      transform: translate3d(-50%, 0, 0);
    }
`;

const doSign = keyframes`
0%,
    20% {
      stroke-dashoffset: 643.4;
    }

    30%,
    100% {
      stroke-dashoffset: 0;
    }
`;

const boltstrike = keyframes`
0%,
    40% {
      transform: translateY(0) rotate(0);
    }
    45% {
      transform: translateY(-8px) rotate(0deg);
    }
    48% {
      transform: translateY(4px) rotate(0deg) scaleX(1.2);
    }
    50% {
      transform: translateY(4px) rotate(5deg) scaleX(1.2);
    }
    52% {
      transform: translateY(4px) rotate(-5deg) scaleX(1.2);
    }
    54% {
      transform: translateY(4px) rotate(5deg) scaleX(1.2);
    }
    56% {
      transform: translateY(4px) rotate(-5deg) scaleX(1.2);
    }
    60%,
    100% {
      transform: translateY(0) rotate(0);
    }
`;

const installProgress = keyframes`
0% {
      width: 0;
    }
    10%,
    15% {
      width: 6px;
    }
    20%,
    25% {
      width: 23px;
    }
    29%,
    33% {
      width: 28px;
    }

    36% {
      width: 31px;
      background: #8abafb;
      transform: scale(1) rotate(0deg);
    }
    37%,
    100% {
      background: #006df6;
    }

    37% {
      transform: scale(0.1) rotate(30deg);
    }
    40% {
      transform: rotate(-10deg);
    }
    42% {
      transform: rotate(3deg);
    }
    44% {
      opacity: 1;
      transform: scale(1);
    }
`;

const StyledLandingFeatures = styled.section`
  background: ${props => props.theme.colors.lightBackground};
  padding: ${props => props.theme.space[2]} ${props => props.theme.space[8]}
    ${props => props.theme.space[8]} ${props => props.theme.space[8]};

  .landing-features {
    & > h2,
    & > p {
      text-align: center;
      padding-right: 16px;
      padding-left: 16px;
      max-width: 700px;
      margin: 20px auto;

      @media only screen and (max-width: ${props =>
          props.theme.breakpoints[0]}) {
        padding-right: 0;
        padding-left: 0;
      }
    }

    .menubar-cvps {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-evenly;
      align-items: center;

      .menubar-cvp {
        min-width: 200px;
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding-top: 20px;
        padding-right: 12px;
        padding-left: 12px;
        padding-bottom: 4px;

        .feature-icon-box {
          align-self: flex-start;
          height: 41px;
          width: 41px;
          background: #e6f1fe;
          border-radius: 100%;
          overflow: hidden;
          line-height: 51px;
          position: relative;
          margin: 12px 0;
          user-select: none;
          -webkit-mask-image: -webkit-radial-gradient(#fff, #000);
          mask-image: -webkit-radial-gradient(#fff, #000);
        }

        & > h3 {
          margin-top: 4px;
          margin-bottom: 12px;
        }

        & > p {
          line-height: 1.6;
        }
      }
    }

    #bolt {
      animation-name: ${boltstrike};
      animation-duration: 5s;
      animation-iteration-count: infinite;
      transform-origin: bottom 5px;
      animation-fill-mode: forwards;
    }

    .line-1,
    .line-3 {
      animation-name: ${fadeInLeft};
      animation-duration: 10s;
      animation-iteration-count: infinite;
      transform: translate3d(0, 0, 0);
    }

    .line-2 {
      animation-name: ${fadeInRight};
      animation-duration: 10s;
      animation-iteration-count: infinite;
      transform: translate3d(-50%, 0, 0);
    }

    .signature {
      width: 90px;
      margin-top: 16px;
      position: absolute;
      left: 0;

      path {
        animation-name: ${doSign};
        animation-duration: 10s;
        animation-fill-mode: forwards;
        animation-iteration-count: infinite;

        transition: stroke-dashoffset 643ms linear;
        stroke-dashoffset: 643.4;
        stroke-dasharray: 643.4, 643.4;
      }
    }

    .progress-container {
      background: #fff;
      border-radius: 6px;
      width: 35px;
      height: 14px;
      position: absolute;
      top: 14px;
      left: 3px;
    }

    .progress-inner {
      position: absolute;
      top: 2px;
      left: 2px;
      background: #8abafb;

      border-radius: 4px;
      width: 31px;
      height: 10px;
      animation-name: ${installProgress};
      animation-duration: 8s;
      animation-iteration-count: infinite;
      animation-fill-mode: forwards;
    }
  }

  @media only screen and (max-width: ${props => props.theme.breakpoints[1]}) {
    padding: ${props => props.theme.space[2]} ${props => props.theme.space[4]}
      ${props => props.theme.space[8]} ${props => props.theme.space[4]};
  }

  @media only screen and (max-width: ${props => props.theme.breakpoints[0]}) {
    padding: ${props => props.theme.space[8]} 0;
  }
`;

export default LandingFeatures;
