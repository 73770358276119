import React, { useEffect } from 'react';
import '../../styles/components/help-tip.scss';

const HelpTip = ({ children }) => {
  useEffect(() => {
    const helpTips = [...document.querySelectorAll('.help-tip')];

    helpTips.forEach(tip => {
      const tipChildren = [...tip.children];
      const icon = tipChildren.find(child =>
        child.className.includes('help-icon')
      );
      const arrow = tipChildren.find(child =>
        child.className.includes('help-tooltip-arrow')
      );
      const tooltip = tipChildren.find(child =>
        child.className.includes('help-tooltip-wrapper')
      );

      if (!icon || !tooltip) return;

      icon.addEventListener('mouseover', () => {
        if (window.innerWidth < 640) {
          const margin = (window.innerWidth - tooltip.clientWidth) / 2;
          const tipX = tip.getBoundingClientRect().x;

          tooltip.style.left = `${margin - tipX}px`;
          tooltip.style.visibility = 'visible';
          arrow.style.visibility = 'visible';
        }
      });
    });
  }, []);

  return (
    <div className="help-tip">
      <span className="help-icon">?</span>
      <div className="help-tooltip-wrapper">
        <div className="help-tooltip-inner">{children}</div>
      </div>
    </div>
  );
};

export default HelpTip;
