// modules
import React from 'react';
import styled from 'styled-components';
import Clipboard from 'react-clipboard.js';

// components
import CLIHeader from './CLIHeader';
import FeatureSection from '../../~reusables/molecules/FeatureSection';
import CoreFeatures from '../LandingPage/LandingFeatures';
import CLIAccessForm from '../../~reusables/molecules/CLIAccessForm';

// styles
import cliFeaturesImg from '../../~reusables/assets/electron-todesktop-features.svg';
import cliInterfaceOverviewImg from '../../~reusables/assets/cli-interface-overview.png';
import controlledRolloutImg from '../../~reusables/assets/controlled-rollout.png';
import versioningImg from '../../~reusables/assets/versioning.png';
import LandingFooter from '../LandingPage/LandingFooter';
import OneClick from '../../~reusables/molecules/OneClick';
import CustomerStory from '../../~reusables/molecules/CustomerStory';

import sunsama from '../../~reusables/assets/sunsama1.svg';
import sunsamaAvatar from '../../~reusables/assets/sunsamaAvatar.png';
import sunsamaIcon from '../../~reusables/assets/sunsamaIcon.png';
import sunsamaIconCaption from '../../~reusables/assets/sunsamaIconCaption.svg';
import sunsamaScreenshot from '../../~reusables/assets/sunsama-screenshot.png';
import quote from '../../~reusables/assets/quote2.svg';

const CLILanding = ({ lead, track }) => {
  return (
    <StyledLanding>
      <CLIHeader lead={lead} track={track} />

      <div style={{ margin: '16 0 0 0' }}>
        <CLIAccessForm />
      </div>

      <FeatureSection
        supertitle="Electron as a Service"
        title="One Command"
        image={{
          loading: 'lazy',
          src: cliFeaturesImg,
          alt: `An illustration showing options to customize the look and behaviour of a generated desktop app.`
        }}
        reversed={false}
      >
        <div className="run-code">
          <code style={{ fontSize: 20 }} id="todesktopDeploy">
            todesktop .
          </code>
          <Clipboard
            onSuccess={() => {
              [...document.querySelectorAll('.click-to-copy')].forEach(el => {
                el.querySelector('.success').classList.add('show');
                setTimeout(() => {
                  el.querySelector('.success').classList.remove('show');
                }, 1000);
              });
            }}
            data-clipboard-text="todesktop ."
            className="click-to-copy"
            data-clipboard-target="#todesktopDeploy"
          >
            Click to Copy
            <span className="success">Copied!</span>
          </Clipboard>
        </div>
        <br />
        <p>
          With one simple command your app is published and{' '}
          <strong>ready for distribution on Windows, Mac and Linux</strong>.
          Your app is built with native installers, auto-updates, code signing
          and crash reports baked-in.
        </p>
        <br />
        <p>
          There's no infrastructure to set-up, no servers to manage and no
          configuration needed.
        </p>
      </FeatureSection>

      <CustomerStory image={sunsamaScreenshot}>
        <div className="customer-callout">
          <div className="content">
            <h3>Customer Story</h3>
            <img src={sunsama} className="customer-story-logo" alt="" />
            <h4>
              Sunsama wanted confidence in their build and deploy process.
              ToDesktop was able to deliver with <b>one command</b>.
            </h4>

            <p className="quote" style={{ backgroundImage: `url(${quote})` }}>
              “Just went through migrating our build/publish process over to
              ToDesktop. Highly recommended. It's a major relief to know that
              updates can be rolled out with a single terminal command and
              autoupdates will always work (with proper code signing).”
              <span
                className="author"
                style={{ backgroundImage: `url(${sunsamaAvatar})` }}
              >
                Travis Meyer
                <br />
                <span>
                  Co-founder of{' '}
                  <a
                    href="https://sunsama.com/"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    Sunsama
                  </a>
                  .
                </span>
              </span>
            </p>
            <div className="customer-story-icon-container">
              <img
                src={sunsamaIconCaption}
                className="customer-story-icon-caption"
                alt=""
              />
              <img
                src={sunsamaIcon}
                width="100"
                height="100"
                className="customer-story-icon"
                alt=""
              />
            </div>
            <p>
              With no extra work, Travis has delivered a customer-ready desktop
              app <b>faster</b> than he could have ever hoped. Now his team can{' '}
              <b> focus on their application code</b> and not worry about the
              ongoing effort of maintaining desktop app infrastructure.
            </p>
          </div>
        </div>
      </CustomerStory>

      <CoreFeatures lead={lead} />
      <div
        style={{
          background:
            'linear-gradient(180deg, #F6F9FD 0%, rgba(246, 249, 253, 0) 100%)'
        }}
      >
        <div style={{ padding: '16px', maxWidth: '1140px', margin: '0 auto' }}>
          <img
            style={{ width: '100%' }}
            src={cliInterfaceOverviewImg}
            alt="A snapshot of the ToDesktop continuous integration and deployment platform."
          />
        </div>
      </div>
      
      <div style={{ marginTop: 24 }}>
        <OneClick lead={lead} />
      </div>

      <FeatureSection
        title="Controlled Rollout"
        image={{
          loading: 'lazy',
          src: controlledRolloutImg,
          alt: `An image with a slider that shows that the desktop app has been rolled out to 64% of the total users.`
        }}
        reversed={true}
      >
        <p>
          ToDesktop enables you to do a <b>staggered released</b> of your
          desktop app. This means you can test your app with a safe set of beta
          users <b>before rolling it out completely</b>.
        </p>
      </FeatureSection>

      <FeatureSection
        title="Relevant Analytics"
        image={{
          loading: 'lazy',
          src: versioningImg,
          alt: `An image showing which desktop app versions are currently in use by customers.`
        }}
        reversed={false}
      >
        <p>
          You can use ToDesktop to track <b>active users</b>,{' '}
          <b>app installs/uninstalls</b>, and even what <b>app versions</b> your
          users are currently using.
        </p>
      </FeatureSection>
      <CLIAccessForm />
      <LandingFooter track={track} />
    </StyledLanding>
  );
};

const StyledLanding = styled.div`
  

  .click-to-copy {
    cursor: pointer;
    transition: transform 300ms;
    transform: translateX(200px);
    width: 200px;
    box-sizing: border-box;
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    background: #797979;
    color: #fff;
    vertical-align: center;
    font-size: 15px;
    line-height: 63px;
    height: 65px;
    font-weight: normal;
    text-transform: uppercase;
    border: 0;
    outline: 0;
    user-select: none;
    &:hover {
      background: #333;
    }

    .success {
      transition: transform 300ms;
      transform: scale(0);
      box-sizing: border-box;
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      width: 200px;
      font-size: 15px;
      background: rgb(2, 66, 228);
      color: #fff;
      line-height: 65px;
      height: 65px;
      &.show {
        transform: scale(1);
      }
    }
  }

  .run-code {
    background: #fffccc;
    text-align: left;
    padding: 0 20px;
    font-size: 20px;
    border: 1px #e6d600 solid;
    position: relative;
    overflow: hidden;
    height: 50px;
    line-height: 50px;
    box-sizing: border-box;
    background: hsl(203, 100%, 90%);
    border: 1px hsl(206, 100%, 85%) solid;
    border-radius: 3px;

    code {
      background-color: transparent;
    }

    .click-to-copy {
      height: 50px;
      line-height: 48px;
      .success {
        height: 50px;
        line-height: 48px;
      }
    }

    &:hover .click-to-copy {
      transform: translateX(0);
    }
  }
`;

export default CLILanding;
