import React from 'react';
import styled from 'styled-components';
import { P1 } from '../../~reusables/atoms/Text';
import { PrimaryButton } from '../../~reusables/atoms/Button';
import { buildWithAppUrl } from '../../actions/buildWithAppUrl';
import CTAWithInput from '../../~reusables/molecules/CTAWithInput';
import { eventTracking } from '../../~reusables/constants/eventTracking';
import desktopScreenshot from '../../~reusables/assets/desktop-screenshot.png';

const LandingCTAv2 = ({ cta, lead, track }) => {
  const { appUrl, companyName, customIcon, autoIcon } = lead;
  return (
    <StyledLandingCTAv2>
      <div className="background-image" />
      <div className="landing-cta-v2">
        <P1>
          Convert your web app to a desktop app in <strong>2 minutes.</strong>
        </P1>
        {cta === 'build-app' ? (
          appUrl ? (
            <PrimaryButton
              className="landing-cta-btn"
              onClick={() => {
                track(eventTracking.submittedURLForm, {
                  url: appUrl,
                  host: 'leads',
                  source: 'build your app cta',
                  location: 'halfway mark'
                });
                buildWithAppUrl(appUrl, companyName, customIcon, autoIcon);
              }}
            >
              Build {companyName} Now
            </PrimaryButton>
          ) : (
            <CTAWithInput
              noShadow
              lead={lead}
              track={track}
              location="halfway mark"
            />
          )
        ) : cta === 'intercom' ? (
          <PrimaryButton className="landing-cta-btn js-intercom">
            Request demo
          </PrimaryButton>
        ) : (
          ''
        )}
      </div>
      <div className="cta-desktop-container">
        <img src={desktopScreenshot} alt="Interface of the ToDesktop Web App" />
      </div>
    </StyledLandingCTAv2>
  );
};

const StyledLandingCTAv2 = styled.section`
  background: transparent;
  box-shadow: inset 0px 11px 15px rgba(151, 162, 185, 0.12),
    inset 0px 24px 38px rgba(151, 162, 185, 0.14);
  display: flex;

  .cta-desktop-container {
    min-height: 100%;
    width: 100%;
    z-index: -1;

    img {
      min-height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  .landing-cta-v2 {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60%;
    padding: ${props => props.theme.space[7]} 5%;
    min-width: 400px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    & > p:first-of-type {
      font-size: 2.2rem;
      margin-bottom: ${props => props.theme.space[7]};
    }
  }

  .landing-cta-btn {
    font-size: ${props => props.theme.fontSizes[4]};
    padding: ${props => `${props.theme.space[6]} ${props.theme.space[8]}`};
  }

  @media only screen and (max-width: ${props => props.theme.breakpoints[1]}) {
    padding: ${props => props.theme.space[8]} 0;
    position: relative;

    .background-image {
      background-image: url(${require('../../~reusables/assets/desktop-screenshot.png')});
      background-repeat: no-repeat;
      background-position: right;
      background-size: contain;
      opacity: 0.25;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      position: absolute;
      z-index: -1;
    }

    .cta-desktop-container {
      display: none;
    }

    .landing-cta-v2 {
      margin: 0 auto;
      width: 75%;
      min-width: 160px;
    }
  }

  @media only screen and (max-width: ${props => props.theme.breakpoints[0]}) {
    .landing-cta-v2 {
      width: 90%;
    }
  }
`;

export default LandingCTAv2;
