import React from 'react';
import styled from 'styled-components';
import Container from '../../~reusables/layout/Container';
import CostCalculator from '../../~reusables/molecules/CostCalculator';

const LandingCalculatorVP = ({ track }) => {
  return (
    <StyledLandingCalculatorVP>
      <Container>
        <CostCalculator track={track} />
      </Container>
    </StyledLandingCalculatorVP>
  );
};

const StyledLandingCalculatorVP = styled.section`
  background: ${props => props.theme.colors.lightBackground};
  overflow-x: hidden;

  padding-top: ${props => props.theme.space[10]};
  padding-bottom: ${props => props.theme.space[8]};

  @media only screen and (max-width: ${props => props.theme.breakpoints[0]}) {
    padding: ${props => props.theme.space[8]} 0;
  }
`;

export default LandingCalculatorVP;
