import React from 'react';
import styled from 'styled-components';

const CustomerStory = ({ image, children }) => (
  <StyledCustomerStory className="customer-story">
    <div className="columns">
      <div className="text">{children}</div>
      <div className="image">
        <div className="customer-screenshot">
          <img
            loading="lazy"
            src={image}
            alt="A ToDesktop customer's app screenshot"
          />
        </div>
      </div>
    </div>
  </StyledCustomerStory>
);

const StyledCustomerStory = styled.div`
  overflow-x: hidden;
  p {
    line-height: 1.5;
  }

  .columns {
    margin-top: 32px;
    display: flex;
    justify-content: space-between;

    .text {
      flex: 8;
      margin-right: 8px;
    }

    .image {
      flex: 7;
    }

    @media screen and (max-width: 860px) {
      flex-direction: column;
      justify-content: center;
      margin: 32px 0 48px 0;

      .text {
        margin-right: 0;
      }
    }
  }

  .customer-callout {
    background: linear-gradient(115.55deg, #009eff 0%, #0242e4 101.17%);
    color: white;
    overflow: hidden;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;

    .content {
      margin: 48px 60px 48px 20px;
      max-width: 480px;
      float: right;
      overflow: hidden;
      padding-bottom: 12px;

      @media screen and (max-width: 860px) {
        margin: 24px 5% 24px 5%;
        max-width: none;
        float: none;

        .text {
          margin-right: 0;
        }
      }
    }

    h3 {
      font-size: 13px;
      text-transform: uppercase;
      color: #ffffc2;
      vertical-align: middle;
      font-weight: 700;
    }

    h4 {
      font-size: 26px;
      font-weight: 200;
      margin-top: 0;
      margin-bottom: 20px;
      line-height: 1.4;

      b {
        font-weight: 400;
      }
    }

    .customer-story-logo {
      display: block;
      margin: 10px 0 10px 0;
      width: 300px;
    }

    .customer-story-icon-container {
      position: relative;
    }

    .customer-story-icon {
      height: 100px;
      display: block;
      margin: 20px auto;
    }

    .customer-story-icon-caption {
      position: absolute;
      top: 35px;
      left: 50%;
      margin-left: -170px;
      width: 120px;

      @media screen and (max-width: 860px) {
        width: 90px;
        top: 45px;
        margin-left: -140px;
      }
    }

    .quote {
      margin: 20px 0;
      line-height: 1.5;
      font-size: 14px;
      background: rgba(0, 0, 0, 0.1) no-repeat 10px 10px;
      color: #fff;
      // border: 1px #0057c2 solid;
      padding: 15px;
      padding-left: 60px;
      border-radius: 3px;

      .author {
        display: block;
        margin-top: 20px;
        line-height: 21px;
        height: 45px;
        background-repeat: no-repeat;
        background-size: 45px;
        padding-left: 60px;

        a {
          color: #fff;
          text-decoration: underline;
        }

        @media screen and (max-width: 860px) {
          height: auto;
        }
      }
    }
  }

  .customer-screenshot {
    img {
      margin-top: 35px;
      margin-left: -40px;
      border-radius: 3px;
      box-shadow: 0px 0px 15px 3px rgba(0, 0, 0, 0.2);
      max-width: 115%;
    }

    @media screen and (max-width: 860px) {
      padding: 0 24px;
      img {
        margin-top: -12px;
        margin-left: 0;
        max-width: 100%;
      }
    }
  }
`;

export default CustomerStory;
