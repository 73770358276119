import React from 'react';
import styled from 'styled-components';
import { MAX_PAGE_WIDTH } from '../../context/ThemeContext';

const Container = props => {
  return <StyledContainer {...props} />;
};

const StyledContainer = styled.section`
  max-width: ${MAX_PAGE_WIDTH}px;
  margin: 0 auto;
  padding: 0 ${props => props.theme.space[8]};

  @media only screen and (max-width: ${props => props.theme.breakpoints[1]}) {
    padding: 0 1.6rem;
  }
`;

export default Container;
