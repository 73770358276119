import React from 'react';
import { Router } from '@reach/router';

import ThemeContextProvider from './context/ThemeContext';
import LandingPage from './components/LandingPage/LandingPage';
import OldLandingPage from './components/LandingPage/OldLandingPage';
import NotFound from './components/NotFound';

const App = () => {
  return (
    <ThemeContextProvider>
      <Router>
        {/* Old Firestore-based paths that were brittle and only differentiated their slugs via their source */}
        {/* We can't delete these right now because they're being used already. And we can't fetch previously existing deals because they aren't mapped to these slugs in any concrete way. */}
        {/*
          TODO: Keep these alive for another couple months (April 2020?) (since a couple dozen of them have already been emailed out) then:
            - get rid of these components
            - the <OldLandingPage /> component
            - the Firestore DB stuff completely
            - refactor lead object structure throughout app with better property names
        */}
        <OldLandingPage path="/yc/:id/:slug" />
        <OldLandingPage path="/l/:slug" />
        <OldLandingPage path="/cl/:slug" />

        {/* Pipedrive as our data's source of truth path. The dealId is important, the slugified company name is for aesthetics. */}
        <LandingPage path="/:pipedriveDealId/:companyNameSlug" />

        <NotFound default />
      </Router>
    </ThemeContextProvider>
  );
};

export default App;
