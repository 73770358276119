import React, { useState, useEffect } from 'react';
import axios from 'axios';
import slugify from '@sindresorhus/slugify';
import { navigate } from '@reach/router';
import '../../vendor/segment.js';

import LandingHeader from './LandingHeader';
import LandingTestimonial from './LandingTestiominal';
import LandingBenefits from './LandingBenefits';
import LandingObjections from './LandingObjections';
import LandingCalculatorVP from './LandingCalculatorVP';
import LandingReassurance from './LandingReassurance';
import LandingFooter from './LandingFooter';
import { handleIntercom } from '../../actions/handleIntercom';
import LandingCTAv2 from './LandingCTAv2';
import LandingFeatures from './LandingFeatures';
import christophePasquier from '../../~reusables/assets/christophe-pasquier.png';
import mathildeCollin from '../../~reusables/assets/mathilde-collin.png';
import CLILanding from '../CLI/CLILanding.js';

const PORT = process.env.PORT || 5001;
const pipedriveDealUrl =
  process.env.NODE_ENV === 'development'
    ? `http://localhost:${PORT}/todesktop-leads/us-central1/getPipedriveDeal`
    : 'https://us-central1-todesktop-leads.cloudfunctions.net/getPipedriveDeal';
const pipedriveLandingPageUpdateUrl =
  process.env.NODE_ENV === 'development'
    ? `http://localhost:${PORT}/todesktop-leads/us-central1/pipedriveLandingPageHasBeenViewed`
    : 'https://us-central1-todesktop-leads.cloudfunctions.net/pipedriveLandingPageHasBeenViewed';

const LandingPage = ({ pipedriveDealId, companyNameSlug }) => {
  const [lead, setLead] = useState();
  const [collection, setCollection] = useState();
  const cta = (lead && lead.cta) || 'build-app';

  useEffect(() => {
    axios
      .get(`${pipedriveDealUrl}?dealId=${pipedriveDealId}`)
      .then(res => {
        const {
          dealId,
          companyName,
          appUrl,
          orgWebsite,
          icon,
          screenshot,
          orgYcBatch,
          personEmail,
          personName,
          landingPageHasBeenViewed,
          orgSource
          // orgYcId,
        } = res.data;

        if (slugify(companyName) !== companyNameSlug) {
          navigate('/404');
        }

        switch (orgSource) {
          case 'YCombinator':
            setCollection('ycLeads');
            break;
          case 'SaaS Network Ireland':
            setCollection('leads');
            break;
          case 'electron_apps2':
          case 'electron_apps':
            setCollection('cli');
            break;
          default:
            setCollection('');
        }

        // Structure lead state the same as OldLandingPage.js so we don't have to refactor everything.
        setLead({
          // This used to refer to YC IDs but it doesn't really make sense since there can be a dynamic number of sources.
          // So some of the very first Segment data might have YC IDs as `id`. Moving forward this will always be Pipedrive Deal IDs.
          id: dealId,
          companyName,
          appUrl: appUrl || orgWebsite,
          autoIcon: icon,
          customIcon: icon,
          autoScreenshot: screenshot,
          customScreenshot: screenshot,
          batch: orgYcBatch,
          email1: personEmail,
          founder1: personName,
          hasLeadLandingPageBeenViewed: Boolean(landingPageHasBeenViewed),
          // Note: This isn't the same "landing page url" that is in Pipedrive.
          // "Landing page", in Firebase, originally referred to the company's main website.
          // Now, in Pipedrive, it refers to the faux desktop app generated page for leads.
          // This 👇 one, throughout this React app, refers to the company's website.
          landingPageUrl: orgWebsite,
          slug: companyNameSlug
        });
      })
      .catch(e => {
        console.error(e);
        navigate('/404');
      });
  }, [pipedriveDealId, companyNameSlug]);

  const track = (event, properties = {}) => {
    let cohort;
    switch (lead.orgSource) {
      case 'YCombinator':
        cohort = 'yc';
        break;
      case 'SaaS Network Ireland':
        cohort = 'l';
        break;
      default:
        cohort = '';
    }

    window.analytics.track(event, {
      companyName: lead.companyName,
      cohort,
      ...properties
    });
  };

  useEffect(() => {
    /* 
      We track whether or not the page was viewed in Pipedrive. If it hasn't been, we assumed the
      user is the person we emailed. We identify via Segment as that person. Segment passes that info
      onto Intercom so when the chat widget opens, it should have the personalized data it needs.
      If the page was viewed before and it's the same person/device, Segment/Intercom knows who
      they are and the chat widget will open again. If the page was viewed before and it's a 
      different person/device, then the chat widget will open but without the personalized data.
    */
    if (
      window.location.hostname !== 'localhost' &&
      lead &&
      !lead.landingPageHasBeenViewed
    ) {
      // https://stackoverflow.com/a/7616484
      const hash = str => {
        let hash = 0,
          i,
          chr;
        if (str === 0) return hash;
        for (i = 0; i < str.length; i++) {
          chr = str.charCodeAt(i);
          hash = (hash << 5) - hash + chr;
          hash |= 0; // Convert to 32bit integer
        }
        return hash;
      };

      const traits = {
        company: {
          id: hash(lead.companyName),
          name: lead.companyName,
          website: lead.orgWebsite,
          ycBatch: lead.orgYcBatch,
          ycId: lead.orgYcId
          // TODO: Import and scrape/import these into Pipedrive
          // fate: d.fate,
          // size: d.employees,
        },
        email: lead.personEmail,
        name: lead.personName
      };
      if (window.analytics) {
        window.analytics.identify(lead.personEmail, traits, {
          integrations: {
            All: true
          }
        });
      }

      axios
        .get(`${pipedriveLandingPageUpdateUrl}?dealId=${pipedriveDealId}`)
        .catch(e => {
          console.error(
            `Couldn't update "Landing page has been viewed in Pipedrive"`
          );
          console.error(e);
        });
    }
  }, []); // eslint-disable-line

  useEffect(() => {
    const intercomLinks = [...document.querySelectorAll('.js-intercom')];
    intercomLinks.forEach(el => el.addEventListener('click', handleIntercom));
  }, [lead]);

  if (lead && collection === 'cli')
    return <CLILanding lead={lead} track={track} />;

  return (
    <div>
      {lead && (
        <>
          <LandingHeader cta={cta} lead={lead} track={track} />
          <main>
            <LandingTestimonial
              collection={collection}
              testimonials={[
                {
                  quote:
                    '<p>People using our desktop app spend on average <strong>34% more time on the app</strong> than those using the web version</p>',
                  name: 'Mathilde Collin',
                  role: 'Founder/CEO of',
                  company: 'Front',
                  companyUrl: 'https://frontapp.com/',
                  avatar: mathildeCollin,
                  batch: 'YC S14',
                  statistic: '↑34%'
                }
              ]}
            />
            <LandingBenefits />
            <LandingObjections lead={lead} />
            {!lead.shouldHideLandingCTASection ? (
              <LandingCTAv2 cta={cta} lead={lead} track={track} />
            ) : (
              ''
            )}
            <LandingTestimonial
              collection={collection}
              testimonials={[
                {
                  quote:
                    '<p>Today, <strong>active desktop users</strong> are triggering <strong>30% more actions</strong> than the average active user</p>',
                  name: 'Christophe Pasquier',
                  role: 'Founder of',
                  company: 'SliteHQ',
                  companyUrl: 'https://slite.com/',
                  avatar: christophePasquier,
                  batch: 'YC S18',
                  statistic: '↑30%'
                }
              ]}
            />
            <LandingFeatures lead={lead} />
            <LandingCalculatorVP track={track} />
            <LandingReassurance cta={cta} lead={lead} track={track} />
          </main>
          <LandingFooter track={track} />
        </>
      )}
    </div>
  );
};

export default LandingPage;
