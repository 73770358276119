import React from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import { MAX_PAGE_WIDTH } from '../../context/ThemeContext';

const BaseSection = ({ className, children }) => (
  <section className={classNames('section', className)}>{children}</section>
);

const FeatureSection = ({
  title,
  supertitle,
  image,
  reversed,
  children,
  imageWrapperClassName,
  className
}) => (
  <StyledFeatureSection
    className={classNames(className, 'feature-section', reversed && 'reversed')}
  >
    <div className="text">
      {supertitle && <h3 className="supertitle">{supertitle}</h3>}
      {title && <h2 className="title">{title}</h2>}
      {children}
    </div>
    <div className={classNames('image', imageWrapperClassName)}>
      <img {...image} alt={image.alt} />
    </div>
  </StyledFeatureSection>
);

const StyledFeatureSection = styled(BaseSection)`
  max-width: ${MAX_PAGE_WIDTH}px;
  margin: 0 auto;
  padding: ${props => props.theme.space[7]} 5% 0 5%;

  @media screen and (min-width: ${props => props.theme.breakpoints[1]}) {
    display: flex;
    align-items: center;
    padding: ${props => props.theme.space[8]} 5% 0 5%;
  }

  &.reversed {
    flex-direction: row-reverse;
  }

  .text {
    flex: 1;
    color: #4a5568;

    p {
      font-size: 16px;
      line-height: 1.4;
    }

    @media screen and (min-width: ${props => props.theme.breakpoints[1]}) {
      p {
        font-size: 20px;
      }
    }

    .supertitle {
      font-size: 28px;
      font-weight: 500;
      font-style: italic;
      margin: 0;
      line-height: 1.25;
      color: #a0aec0;

      @media screen and (min-width: ${props => props.theme.breakpoints[1]}) {
        font-size: 40px;
      }
    }

    .title {
      font-size: 32px;
      font-weight: 800;
      margin-top: 0;
      margin-bottom: 16px;
      line-height: 1.25;
      color: #1a202c;

      @media screen and (min-width: ${props => props.theme.breakpoints[1]}) {
        font-size: 40px;
      }
    }

    .with-icon {
      display: flex;
      margin-bottom: 8px;

      .icon {
        width: 32px;
        height: auto;
        margin-right: 16px;
      }

      p {
        margin-top: 0;
        margin-bottom: 0;
      }
    }
  }

  .image {
    flex: 1;
    padding: 32px;
    padding-right: 0;
    @media screen and (max-width: ${props => props.theme.breakpoints[1]}) {
      padding-left: 0;
    }
    img {
      max-width: 100%;
    }
  }
  &.reversed .image {
    padding-right: 32px;
    padding-left: 0;
    @media screen and (max-width: ${props => props.theme.breakpoints[1]}) {
      padding-right: 0;
    }
  }
`;

export default FeatureSection;
