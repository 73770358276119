import React, { useEffect } from 'react';
import styled from 'styled-components';
import Clipboard from 'react-clipboard.js';

const CLIHeader = ({ showTerminal = true, showInstallCode = true, lead }) => {
  const {
    companyName,
    customIcon,
    autoIcon,
    landingPageUrl,
    slug,
    founder1
  } = lead;

  const downloadURL = new URL(landingPageUrl).hostname;

  useEffect(() => {
    (global => {
      const typingInterval = 60;

      const eraseAndType = async (el, newText, speed) => {
        const erase = function* erase() {
          while (el.textContent.length > 0) {
            yield (el.textContent = el.textContent.slice(0, -1));
          }
        };
        const eraseGen = erase();
        const type = function* type() {
          let currentChar = 1;
          while (currentChar - 1 !== newText.length) {
            el.textContent = newText.slice(0, currentChar);
            yield (currentChar = currentChar + 1);
          }
        };
        const typeGen = type();

        while (!eraseGen.next().done) {
          await wait(speed || typingInterval);
        }
        while (!typeGen.next().done) {
          await wait(speed || typingInterval);
        }
      };

      const wait = ms => new Promise(resolve => setTimeout(resolve, ms));

      async function infoCycle(el, infoArray, ms) {
        const tick = ms / infoArray.length;
        for (let n = 0; n < infoArray.length; n++) {
          el.innerText = infoArray[n];
          await wait(tick);
        }
        await wait(tick);
        el.innerHTML = '<span style="color: hsl(120, 100%, 60%)">✔</span>';
      }

      function asciiPercent(percent) {
        const stringArray = ['['];
        for (let n = 0; n < 25; n++) {
          if (percent < (n + 1) * 4) {
            stringArray.push('-');
          } else {
            stringArray.push('█');
          }
        }
        stringArray.push(']');
        return stringArray.join('');
      }

      async function animateProgressBar(el, ms) {
        const tick = ms / 100;
        for (let n = 0; n < 102; n++) {
          const str = asciiPercent(n);
          el.innerText = str;
          await wait(tick);
        }
      }

      const terminal = `
  <div class="terminal macos dark">
    <div class="toolbar transparent">
      <div class="close"></div>
      <div class="minimize"></div>
      <div class="zoom"></div>
      <div style="font-size: 12px" class="title">${
        founder1 ? founder1 : 'dave'
      }@${companyName}-MacBook: ~/code/${slug}</div>
    </div>
    <div class="inner">
      <span style="color:rgb(51, 255, 0); padding-right: 10px;">➜</span>
      <span style="color: rgb(0, 255, 255); font-weight: bold;">${slug}</span>
      <span style="color: #999">&gt;</span>
      <span class="terminal-text" id="terminalText"></span><span id="terminalCursor" class="cursor"></span>

      <div id="appVersion" style="display:none;"><span style="color:rgb(0, 255, 255)">ℹ</span> ${companyName} App v1.3.1</div>
      <div id="uploadProgress" style="display:none;">
        <span style="white-space: pre">Preparing:</span>
        <span id="progressBarUpload" style="font-family: monospace;"></span>
        <span id="progressBarUploadText">Uploading</span>
      </div>  
    
      <div id="deployingText" style="display:none;"><br><span style="color:rgb(0, 255, 255)">ℹ</span> Publishing…</br></div>

      <div id="progressMac" style="display:none;">
        <span style="white-space: pre">Mac:      </span>
        <span id="progressBarMac" style="font-family: monospace;"></span>
        <span id="progressBarMacText">Packaging</span>
      </div>
      <div id="progressWindows" style="display:none;">
        <span style="white-space: pre">Windows:  </span>
        <span id="progressBarWindows" style="font-family: monospace;"></span>
        <span id="progressBarWindowsText">Packaging</span>
      </div>  
      <div id="progressLinux" style="display:none;">
        <span style="white-space: pre">Linux:    </span>
        <span id="progressBarLinux" style="font-family: monospace;"></span>
        <span id="progressBarLinuxText">Packaging</span>
      </div>

      <div id="progressComplete" style="display:none;">
        <br><span style="color: hsl(120, 100%, 60%)">✔ Published v1.3.1</span>
        <br>Available at <span style="text-decoration: underline;">https://download.${
          downloadURL ? downloadURL.replace('www.', '') : 'mydomain.com'
        }/</span>
      <div>

    </div>
  </div>`;

      const $terminal = document.querySelector('div.terminal');
      $terminal.innerHTML = terminal;

      async function terminalStart() {
        const $terminalText = document.querySelector('#terminalText');
        const $progressUpload = document.querySelector('#uploadProgress');
        const $progressBarUpload = document.querySelector('#progressBarUpload');
        const $progressBarUploadText = document.querySelector(
          '#progressBarUploadText'
        );
        const $progressBarMac = document.querySelector('#progressBarMac');
        const $progressBarMacText = document.querySelector(
          '#progressBarMacText'
        );
        const $progressMac = document.querySelector('#progressMac');
        const $progressBarWindows = document.querySelector(
          '#progressBarWindows'
        );
        const $progressBarWindowsText = document.querySelector(
          '#progressBarWindowsText'
        );
        const $progressWindows = document.querySelector('#progressWindows');
        const $progressBarLinux = document.querySelector('#progressBarLinux');
        const $progressBarLinuxText = document.querySelector(
          '#progressBarLinuxText'
        );
        const $progressLinux = document.querySelector('#progressLinux');
        const $appVersion = document.querySelector('#appVersion');
        const $deployingText = document.querySelector('#deployingText');
        const $progressComplete = document.querySelector('#progressComplete');

        await eraseAndType($terminalText, 'todesktop . ');

        await wait(400);
        $appVersion.style.display = 'block';
        await wait(400);

        $progressUpload.style.display = 'block';
        infoCycle(
          $progressBarUploadText,
          ['Initializing', 'Preparing', 'Uploading'],
          1000
        );
        await animateProgressBar($progressBarUpload, 1000);
        await wait(400);

        $deployingText.style.display = 'block';

        $progressMac.style.display = 'block';
        infoCycle(
          $progressBarMacText,
          ['Packging', 'Signing', 'Notarizing', 'Uploading'],
          1000
        );
        animateProgressBar($progressBarMac, 1000);

        $progressLinux.style.display = 'block';
        infoCycle($progressBarLinuxText, ['Packging', 'Uploading'], 600);
        animateProgressBar($progressBarLinux, 600);

        $progressWindows.style.display = 'block';
        infoCycle(
          $progressBarWindowsText,
          ['Packging', 'Signing', 'Uploading'],
          1600
        );
        await animateProgressBar($progressBarWindows, 1800);

        await wait(300);
        $progressComplete.style.display = 'block';
      }

      terminalStart();
    })(window);
  }, [companyName, downloadURL, founder1, slug]);

  return (
    <StyledHeader>
      <header className="hero-header">
        <div className="bg-design-1"></div>
        <div className="bg-design-2"></div>
        <div className="bg-border"></div>
        <div className="container" style={{ padding: '0px 12px' }}>
          <div className="logo">
            <div className="handshake-header">
              <img
                onClick={() => (window.location = landingPageUrl)}
                className="icon"
                src={customIcon || autoIcon}
                alt={companyName}
              />
              {(customIcon || autoIcon) && (
                <span
                  className="handshake-emoji"
                  role="img"
                  aria-label="handshake emoji"
                >
                  🤝
                </span>
              )}
              <a href="https://todesktop.com">
                <img
                  className="logo"
                  src={require('../../images/todesktop-logo-sq.svg')}
                  alt="ToDesktop logo"
                />
              </a>
            </div>
          </div>

          {showTerminal && (
            <div className="explainer">
              <div className="terminal"></div>
              <div style={{ marginTop: '-32px', marginBottom: '32px' }}>
                <a
                  style={{
                    color: 'white',
                    fontWeight: 'bold',
                    fontSize: 17
                  }}
                  href="https://docs.todesktop.com/cli/using-the-todesktop-cli"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  See usage docs
                </a>
              </div>
            </div>
          )}
        </div>
      </header>

      {showInstallCode && (
        <div className="install-code">
          <code
            id="npmInstallToDesktop"
            title="Use this command to install ToDesktop CLI using NPM"
          >
            npm install -g @todesktop/cli
          </code>
          <Clipboard
            onSuccess={() => {
              [...document.querySelectorAll('.click-to-copy')].forEach(el => {
                el.querySelector('.success').classList.add('show');
                setTimeout(() => {
                  el.querySelector('.success').classList.remove('show');
                }, 1000);
              });
            }}
            data-clipboard-text="npm install -g @todesktop/cli"
            className="click-to-copy"
            data-clipboard-target="#npmInstallToDesktop"
          >
            Click to Copy
            <span className="success">Copied!</span>
          </Clipboard>
        </div>
      )}
    </StyledHeader>
  );
};

const StyledHeader = styled.header`
  .handshake-header {
    display: flex;
    align-items: center;
    justify-content: center;

    .handshake-emoji {
      font-size: ${props => props.theme.fontSizes[6]};
      margin: 0 ${props => props.theme.space[6]};
    }

    img {
      -webkit-filter: drop-shadow(3px 3px 3px rgba(0, 0, 0, 0.1));
      filter: drop-shadow(3px 3px 3px rgba(0, 0, 0, 0.1));
    }
    .icon,
    .logo {
      max-height: 56px;
      cursor: pointer;

      transition: all 200ms ease;

      &:hover {
        transform: translateY(-2px);
      }
    }
  }

  #npmInstallToDesktop {
    font-size: 24px;
    @media screen and (max-width: ${props => props.theme.breakpoints[0]}) {
      font-size: 16px;
    }
  }

  .install-code {
    overflow: hidden;
    box-sizing: border-box;
    position: relative;
    background: hsl(203, 100%, 90%);
    text-align: center;
    font-size: 30px;
    line-height: 65px;
    height: 65px;
    font-weight: bold;
    code {
      font-family: 'Lucida Console', monospace;
      background-color: transparent;
    }
    border-bottom: 1px hsl(206, 100%, 85%) solid;

    small {
      display: block;
      font-size: 11px;
      font-weight: normal;
      color: #444;
      line-height: 20px;
      margin-top: -10px;
    }

    @media screen and (max-width: ${props => props.theme.breakpoints[0]}) {
      font-size: 16px;
      line-height: 65px;
    }

    &:hover .click-to-copy {
      transform: translateX(0);
    }
  }

  /** Terminal */
  .terminal {
    background: #222;
    position: relative;
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    border-radius: 4px;
    color: #fff;
    margin-bottom: 60px;
    box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.55);

    div {
      margin-bottom: 10px;
    }

    @media screen and (max-width: ${props => props.theme.breakpoints[0]}) {
      width: 100%;
    }

    .title {
      @media screen and (max-width: ${props => props.theme.breakpoints[0]}) {
        display: none;
      }
    }

    .toolbar {
      background: transparent;
      border: none;
      color: #fff;
      text-shadow: none;
      font-size: 12px;
      height: 34px;
      line-height: 34px;
      text-rendering: geometricPrecision;
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
        Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
      .close,
      .minimize,
      .zoom {
        top: 10px;
      }
    }
    .inner {
      height: 300px;
      font-family: 'Lucida Console', monospace;
      text-align: left;
      padding: 12px 14px;
      overflow: hidden;

      @media screen and (max-width: ${props => props.theme.breakpoints[0]}) {
        span,
        div {
          font-size: 11px;
        }
      }

      .cursor {
        margin-left: -7px;
        width: 10px;
        height: 21px;
        display: inline-block;
        background-color: rgb(248, 28, 229);
        border-color: rgb(248, 28, 229);
        vertical-align: middle;
      }

      .terminal-text {
        margin-left: 5px;
      }

      #fakeLink {
        cursor: pointer;
        color: #ff2e88;
        &:hover {
          text-decoration: underline;
        }
      }
    }

    .close,
    .minimize,
    .zoom {
      position: absolute;
      top: 5px;
      display: block;
      width: 12px;
      height: 12px;
      border-radius: 100%;
      background: #ff3b47;
      text-indent: -9999px;
      border: 1px #f21a2e solid;
    }

    .close {
      left: 10px;
      background: radial-gradient(#ff3b47, #ff4a53);
      border-color: #e81328;
    }

    .minimize {
      left: 30px;
      background: radial-gradient(#ffbd00, #ffc400);
      border-color: #e69e12;
    }

    .zoom {
      left: 50px;
      background: radial-gradient(#00d640, #00da43);
      border-color: #12b728;
    }
  }

  /** Header */
  .logo {
    position: relative;
    z-index: 3;
    margin-top: 24px;
    margin-bottom: 24px;
  }

  .hero-header {
    background: rgb(0, 158, 255);
    text-align: center;
    overflow: hidden;
    position: relative;
  }

  @keyframes spotlight {
    from {
      opacity: 0;
    }
    to {
      opacity: 0.7;
    }
  }

  .bg-design-1 {
    width: 140vw;
    height: 50vh;
    position: absolute;
    background: white;
    transform: rotate(17deg);
    left: -15vw;
    top: 30vh;
    background-image: linear-gradient(
      2deg,
      rgb(2, 66, 228) 0%,
      rgb(0, 158, 255) 71%
    );
    opacity: 0.7;
    z-index: 2;
  }

  .bg-design-2 {
    width: 140vw;
    height: 50vh;
    position: absolute;
    background: white;
    transform: rotate(-17deg);
    right: -15vw;
    top: -30vh;
    background-image: linear-gradient(
      2deg,
      rgb(2, 66, 228) 0%,
      rgb(0, 158, 255) 71%
    );
    opacity: 0.7;
    z-index: 1;
  }

  .headline,
  .explainer {
    position: relative;
    z-index: 3;
  }

  h1.hero-text {
    opacity: 1;
    text-align: center;
    font-weight: normal;
    color: #fff;
    line-height: 25px;
    font-size: 20px;
    margin: 0;
    margin-bottom: 60px;
    margin-top: 15px;

    strong {
      font-size: 30px;
      span > span {
        bottom: 6px;
      }
    }

    span {
      position: relative;
      > div {
        display: inline;
        position: relative;
        z-index: 1;
      }
      > span {
        display: block;
        position: absolute;
        background: rgba(255, 255, 255, 0.5);

        height: 3px;
        width: 100%;
        bottom: 2px;
        left: 0;
      }
    }

    @media screen and (max-width: ${props => props.theme.breakpoints[0]}) {
      font-size: 40px;
      line-height: 70px;
      strong {
        font-size: 55px;
      }
    }
  }

  #thanks {
    margin: 100px auto;
    text-align: center;
  }

  #purchase {
    margin: 100px auto;
    display: block;
    background: linear-gradient(115.55deg, #009eff 0%, #0242e4 101.17%);
    box-shadow: 0px 6.9px 8.05px rgba(168, 183, 203, 0.09),
      0px 5.75px 17.25px rgba(168, 183, 203, 0.07),
      0px 16.1px 21.85px rgba(168, 183, 203, 0.07);
    cursor: pointer;
    font-weight: 500;
    padding: $space-4 $space-5;
    border-radius: 4px;
    color: white;
    font-size: $body-2;

    &:hover {
      opacity: 0.9;
    }

    &:active {
      opacity: 0.8;
    }

    &[disabled] {
      opacity: 0.6 !important;
      cursor: default;
    }

    @media screen and (max-width: ${props => props.theme.breakpoints[0]}) {
      width: 100%;
    }
  }
`;

export default CLIHeader;
