import React from 'react';
import { ThemeProvider } from 'styled-components';

export const MAX_PAGE_WIDTH = 1280;

export const theme = {
  breakpoints: ['420px', '767px'],
  space: [
    0, // 0
    '0.1rem', // 1
    '0.2rem', // 2
    '0.3rem', // 3
    '0.5rem', // 4
    '0.8rem', // 5
    '1.3rem', // 6
    '2.1rem', // 7
    '3.4rem', // 8
    '5.5rem', // 9
    '8.9rem', // 10
    '14.4rem', // 11
    '23.3rem' // 12
  ],
  fontSizes: [
    '1.0rem', // 0
    '1.2rem', // 1
    '1.4rem', // 2
    '1.6rem', // 3
    '1.9rem', // 4
    '2.4rem', // 5
    '3.2rem', // 6
    '4.0rem', // 7
    '4.8rem' // 8
  ],
  fontWeights: [
    100, // 0
    200, // 1
    300, // 2
    400, // 3
    500, // 4
    600, // 5
    700, // 6
    800, // 7
    900 // 8
  ],
  radii: [
    0, // 0
    2, // 1
    4, // 2
    6, // 3
    16, // 4
    9999, // 5
    '100%' // 6
  ],
  borders: [
    0, // 0
    '1px solid', // 1
    '2px solid', // 2
    '4px solid' // 3
  ],
  lineHeights: {
    solid: 1,
    title: 1.25,
    copy: 1.5
  },
  letterSpacing: {
    normal: 'normal',
    tracked: '0.1em',
    tight: '0.05em',
    mega: '0.25em'
  },
  fonts: {
    sansSerif:
      '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif'
  },
  shadows: {
    shallow:
      '0px 6px 7px rgba(168, 183, 203, 0.09), 0px 5px 15px rgba(168, 183, 203, 0.07), 0px 14px 19px rgba(168, 183, 203, 0.07)',
    shallowDark:
      '0px 6px 7px rgba(26, 32, 44, 0.09), 0px 5px 15px rgba(26, 32, 44, 0.07), 0px 14px 19px rgba(26, 32, 44, 0.07)',
    deep:
      '0px 11px 15px rgba(151, 162, 185, 0.2), 0px 9px 46px rgba(151, 162, 185, 0.12), 0px 24px 38px rgba(151, 162, 185, 0.14)',
    deepDark:
      '0px 11px 15px rgba(26, 32, 44, 0.2), 0px 9px 46px rgba(151, 162, 185, 0.12), 0px 24px 38px rgba(26, 32, 44, 0.14)'
  },
  colors: {
    white: '#FFFFFF',
    primary: '#006df6',
    title: '#1A202C',
    text: '#4A5568',
    lightTitle: '#FFFFFF',
    lightText: '#eef1f7',
    background: '#182432',
    lightBackground: '#F6F9FD',
    grey: '#d1ddeb',
    primaries: [
      '#2139c4',
      '#145ce3',
      '#006ff6',
      '#0082ff',
      '#0091ff',
      '#0fa2ff',
      '#50b3ff',
      '#89c9ff',
      '#b9ddff',
      '#e2f2ff'
    ],
    greys: [
      '#313d4d',
      '#445365',
      '#54667c',
      '#657a93',
      '#738aa5',
      '#889bb4',
      '#9daec5',
      '#b8c6d8',
      '#d1ddeb',
      '#ebf1fc'
    ]
  }
};

const ThemeContextProvider = props => (
  <ThemeProvider theme={theme}>{props.children}</ThemeProvider>
);

export default ThemeContextProvider;
