import React, { useState } from 'react';
import styled from 'styled-components';
import { FaApple, FaWindows } from 'react-icons/fa';
import { eventTracking } from '../constants/eventTracking';

const OSPreviewSwitcher = ({ callback, lead, track }) => {
  const [previewOS, setPreviewOS] = useState('mac');

  const radioChangeOSPreview = e => {
    setPreviewOS(e.target.value);
    if (callback) callback(e.target.value);
  };

  return (
    <StyledSwticher>
      <div className="radio-toolbar">
        <input
          type="radio"
          id="radioMac"
          name="osPreviewSwitcher"
          value="mac"
          onChange={radioChangeOSPreview}
          checked={previewOS === 'mac'}
        />
        <label
          className="label-mac"
          htmlFor="radioMac"
          style={
            lead.shouldUseMinimalOSSwitcher ? { padding: '0.5rem 1rem' } : {}
          }
        >
          <div>
            <FaApple
              style={
                lead.shouldUseMinimalOSSwitcher
                  ? {}
                  : {
                      marginRight: '8px'
                    }
              }
            />
            <span
              style={
                lead.shouldUseMinimalOSSwitcher
                  ? {
                      display: 'none'
                    }
                  : {}
              }
            >
              Mac
            </span>
          </div>
        </label>

        <input
          type="radio"
          id="radioWindows"
          name="osPreviewSwitcher"
          value="windows"
          onChange={e => {
            track(eventTracking.previewOS);
            radioChangeOSPreview(e);
          }}
          checked={previewOS === 'windows'}
        />
        <label
          className="label-windows"
          htmlFor="radioWindows"
          style={
            lead.shouldUseMinimalOSSwitcher ? { padding: '0.5rem 1rem' } : {}
          }
        >
          <div>
            <FaWindows
              style={
                lead.shouldUseMinimalOSSwitcher
                  ? {}
                  : {
                      marginRight: '8px'
                    }
              }
            />
            <span
              style={
                lead.shouldUseMinimalOSSwitcher
                  ? {
                      display: 'none'
                    }
                  : {}
              }
            >
              Windows
            </span>
          </div>
        </label>
      </div>
      {/* <div className="try-it-arrow">
        <TryItArrow />
      </div> */}
    </StyledSwticher>
  );
};

const StyledSwticher = styled.div`
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  margin-top: ${props => props.theme.space[5]};

  .radio-toolbar {
    display: flex;
  }

  .radio-toolbar,
  .try-it-arrow {
    margin-bottom: ${props => props.theme.space[6]};
  }

  .radio-toolbar input[type='radio'] {
    opacity: 0;
    position: fixed;
    width: 0;
  }

  .radio-toolbar label {
    display: inline-block;
    background-color: white;
    cursor: pointer;
    padding: 10px 20px;
    border: 1px solid ${props => props.theme.colors.grey};

    div {
      font-size: ${props => props.theme.fontSizes[4]};
      display: flex;
    }
  }

  .radio-toolbar input[type='radio']:checked + label {
    color: ${props => props.theme.colors.primaries[4]};
    border: 1px solid ${props => props.theme.colors.primaries[4]};
  }

  .radio-toolbar label:hover {
    transition: 0.3s;
    color: ${props => props.theme.colors.primaries[6]};
  }

  .label-windows {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  .label-mac {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  @media only screen and (max-width: ${props => props.theme.breakpoints[1]}) {
    .radio-toolbar label div {
      font-size: ${props => props.theme.fontSizes[3]};
    }
  }
`;

export default OSPreviewSwitcher;
