import React, { useEffect } from 'react';
import '../../styles/components/one-click.scss';

import oneClickAppLabel from '../assets/one-click/app-label.svg';
import oneClickCursor from '../assets/one-click/cursor.svg';
import oneClickLogo from '../assets/one-click/logo.svg';
import oneClickLogoColor from '../assets/one-click/logo-color.svg';

const OneClick = ({ lead }) => {
  useEffect(() => {
    require('../utils/one-click');
  }, []);

  return (
    <div className="icon" style={{ textAlign: 'center' }}>
      <div className="one-click-container">
        <img
          src={oneClickAppLabel}
          alt="Your app"
          className="app-label"
          height="40"
          width="120"
          style={{ width: '120px' }}
        />
        <div className="icon-container">
          <div className="confetti-root" />
          <img
            src={lead.customIcon || lead.autoIcon || oneClickLogo}
            alt="Grayscale logo before being clicked"
            className="app-icon"
            height="120"
            width="120"
            style={{
              width: '100%',
              borderRadius: '4px',
              filter: 'grayscale(100%)'
            }}
          />
          <img
            src={lead.customIcon || lead.autoIcon || oneClickLogoColor}
            alt="Full-color logo with confetti exploding after being clicked"
            className="app-icon-color"
            height="120"
            width="61"
            style={{ width: '100%', borderRadius: '4px' }}
          />
          <div className="icon-shadow" />
        </div>
        <img
          src={oneClickCursor}
          alt="A mouse cursor"
          className="cursor"
          height="18"
          width="12"
        />
      </div>
    </div>
  );
};

export default OneClick;
