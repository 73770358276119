import React, { useState, useEffect } from 'react';
import pick from 'lodash-es/pick';
import styled from 'styled-components';
import firebase from 'firebase/app';
import 'firebase/firestore';

import LandingHeader from './LandingHeader';
import LandingTestimonial from './LandingTestiominal';
import LandingBenefits from './LandingBenefits';
import LandingObjections from './LandingObjections';
import LandingCalculatorVP from './LandingCalculatorVP';
import LandingReassurance from './LandingReassurance';
import LandingFooter from './LandingFooter';
import '../../vendor/segment.js';
import { handleIntercom } from '../../actions/handleIntercom';
import LandingCTAv2 from './LandingCTAv2';
import { navigate } from '@reach/router';
import LandingFeatures from './LandingFeatures';
import christophePasquier from '../../~reusables/assets/christophe-pasquier.png';
import mathildeCollin from '../../~reusables/assets/mathilde-collin.png';

firebase.initializeApp({
  apiKey: 'AIzaSyDeC6P_xECojwi2GI1C3UJU4vzYjGV7avo',
  authDomain: 'todesktop-leads.firebaseapp.com',
  databaseURL: 'https://todesktop-leads.firebaseio.com',
  projectId: 'todesktop-leads',
  storageBucket: 'todesktop-leads.appspot.com',
  messagingSenderId: '1033246018526',
  appId: '1:1033246018526:web:8775bdeb54d5c84e3b6b4f'
});

const db = firebase.firestore();

const OldLandingPage = ({ slug, location, id }) => {
  let collection;
  switch (location.pathname.split('/')[1]) {
    case 'yc':
      collection = 'ycLeads';
      break;
    case 'l':
      collection = 'leads';
      break;
    case 'cl':
      collection = 'customLeads';
      break;
    default:
      collection = '';
  }

  const [lead, setLead] = useState({});
  const cta = lead.cta || 'build-app';

  const track = (event, properties = {}) => {
    window.analytics.track(event, {
      companyName: lead.companyName,
      cohort: location.pathname.split('/')[1],
      ...properties
    });
  };

  useEffect(() => {
    const docRef = db.collection(collection).doc(slug);

    docRef
      .get()
      .then(doc => {
        const d = doc.data();

        if (collection === 'ycLeads' && d.id !== id) {
          navigate('/');
        }

        /* 
          We track whether or not the page was viewed, in Firebase. If it hasn't been, we assumed the
          user is the person we emailed. We identify via Segment as that person. They pass that info
          onto Intercom so when the chat widget opens, it should have the personalized data it needs.
          If the page was viewed before and it's the same person/device, Segment/Intercom knows who
          they are and the chat widget will open again. If the page was viewed before and it's a 
          different person/device, then the chat widget will open but without the personalized data.
        */
        if (
          window.location.hostname !== 'localhost' &&
          !d.hasLeadLandingPageBeenViewed
        ) {
          let founderEmail;
          let founderName;

          if (collection === 'customLeads' || collection === 'leads') {
            founderEmail = d.email;
            founderName = d.contact;
          } else {
            founderEmail = d.email1;
            founderName = d.founder1;
          }

          // https://stackoverflow.com/a/7616484
          const hash = str => {
            let hash = 0,
              i,
              chr;
            if (str === 0) return hash;
            for (i = 0; i < str.length; i++) {
              chr = str.charCodeAt(i);
              hash = (hash << 5) - hash + chr;
              hash |= 0; // Convert to 32bit integer
            }
            return hash;
          };

          const traits = {
            company: {
              id: hash(d.companyName),
              fate: d.fate,
              name: d.companyName,
              size: d.employees,
              website: d.landingPageUrl,
              ycBatch: d.batch,
              ycId: d.id,
              ...pick(d, [
                'isHiring',
                'lat',
                'lng',
                'location',
                'prettyDirectoryLocation',
                'sectors'
              ])
            },
            email: founderEmail,
            name: founderName
          };
          if (window.analytics) {
            window.analytics.identify(founderEmail, traits, {
              integrations: {
                All: true
              }
            });
          }

          docRef.update({
            hasLeadLandingPageBeenViewed: true
          });
        }

        setLead(d);
      })
      .catch(e => console.error(e));
  }, []); // eslint-disable-line

  useEffect(() => {
    const intercomLinks = [...document.querySelectorAll('.js-intercom')];
    intercomLinks.forEach(el => el.addEventListener('click', handleIntercom));
  }, [lead]);

  return (
    <StyledOldLandingPage>
      {lead && Object.entries(lead).length !== 0 && (
        <>
          <LandingHeader cta={cta} lead={lead} track={track} />
          <main>
            <LandingTestimonial
              collection={collection}
              testimonials={[
                {
                  quote:
                    '<p>People using our desktop app spend on average <strong>34% more time on the app</strong> than those using the web version</p>',
                  name: 'Mathilde Collin',
                  role: 'Founder/CEO of',
                  company: 'Front',
                  companyUrl: 'https://frontapp.com/',
                  avatar: mathildeCollin,
                  batch: 'YC S14',
                  statistic: '↑34%'
                }
              ]}
            />
            <LandingBenefits />
            <LandingObjections lead={lead} />
            {!lead.shouldHideLandingCTASection ? (
              <LandingCTAv2 cta={cta} lead={lead} track={track} />
            ) : (
                ''
              )}
            <LandingTestimonial
              collection={collection}
              testimonials={[
                {
                  quote:
                    '<p>Today, <strong>active desktop users</strong> are triggering <strong>30% more actions</strong> than the average active user</p>',
                  name: 'Christophe Pasquier',
                  role: 'Founder of',
                  company: 'SliteHQ',
                  companyUrl: 'https://slite.com/',
                  avatar: christophePasquier,
                  batch: 'YC S18',
                  statistic: '↑30%'
                }
              ]}
            />
            <LandingFeatures lead={lead} />
            <LandingCalculatorVP track={track} />
            {/* <LandingCustomizeVP />
            <LandingDistributionVP /> */}
            <LandingReassurance cta={cta} lead={lead} track={track} />
          </main>
          <LandingFooter track={track} />
        </>
      )}
    </StyledOldLandingPage>
  );
};

const StyledOldLandingPage = styled.div``;

export default OldLandingPage;
