async function animateDesktop() {
  try {
    const delay = ms => new Promise(_ => setTimeout(_, ms));

    const icon = document.querySelector('.one-click-container .app-icon');
    const colorIcon = document.querySelector(
      '.one-click-container .app-icon-color'
    );
    const iconBounds = icon.getBoundingClientRect();
    const cursor = document.querySelector('.one-click-container .cursor');
    const cursorBounds = cursor.getBoundingClientRect();
    const appLabel = document.querySelector('.one-click-container .app-label');

    const [cursX, cursY] = [
      iconBounds.x - cursorBounds.x + iconBounds.width / 2,
      iconBounds.y - cursorBounds.y + iconBounds.height / 2
    ];

    await cursor.animate(
      [
        { transform: 'translate(0px, 0px)', opacity: 1 },
        {
          transform: `translate(${cursX}px, ${cursY}px)`,
          opacity: 1
        }
      ],
      {
        duration: 500,
        iterations: 1,
        fill: 'forwards'
      }
    ).finished;

    await appLabel.animate(
      [
        { transform: 'translateY(-40px)', opacity: 0 },
        { transform: 'translateY(-50px)', opacity: 1 }
      ],
      {
        duration: 500,
        iterations: 1,
        fill: 'forwards'
      }
    ).finished;

    await delay(1500);

    await Promise.all([
      icon.animate(
        [
          { transform: 'translateY(0px)' },
          { transform: 'translateY(6px)' },
          { transform: 'translateY(0px)' }
        ],
        {
          duration: 250,
          iterations: 1,
          fill: 'forwards'
        }
      ).finished,
      appLabel.animate(
        [
          { transform: 'translateY(-50px)', opacity: 1 },
          { transform: 'translateY(-44px)', opacity: 1 }
        ],
        {
          duration: 250,
          iterations: 1,
          fill: 'forwards'
        }
      ).finished
    ]);

    await Promise.all([
      icon.animate([{ opacity: 1 }, { opacity: 0 }], {
        duration: 100,
        iterations: 1,
        fill: 'forwards'
      }).finished,
      colorIcon.animate([{ opacity: 0 }, { opacity: 1 }], {
        duration: 100,
        iterations: 1,
        fill: 'forwards'
      }).finished,
      appLabel.animate(
        [
          { transform: 'translateY(-50px)', opacity: 1 },
          { transform: 'translateY(-100px)', opacity: 0 }
        ],
        {
          duration: 100,
          iterations: 1,
          fill: 'forwards'
        }
      ).finished
    ]);

    window.confetti(document.querySelector('.confetti-root'), {
      angle: 90,
      spread: 81,
      startVelocity: 30,
      elementCount: 40,
      decay: 0.9
    });

    await delay(200);

    await cursor.animate(
      [
        { opacity: 1 },
        {
          opacity: 0
        }
      ],
      {
        duration: 200,
        iterations: 1,
        fill: 'forwards'
      }
    ).finished;

    await delay(12000);

    await Promise.all([
      icon.animate([{ opacity: 0 }, { opacity: 1 }], {
        duration: 100,
        iterations: 1,
        fill: 'forwards'
      }).finished,
      colorIcon.animate([{ opacity: 1 }, { opacity: 0 }], {
        duration: 100,
        iterations: 1,
        fill: 'forwards'
      }).finished,
      cursor.animate(
        [
          { transform: 'translate(0px, 0px)', opacity: 1 },
          {
            transform: `translate(0px, 0px)`,
            opacity: 1
          }
        ],
        {
          duration: 500,
          iterations: 1,
          fill: 'forwards'
        }
      ).finished
    ]);

    await animateDesktop();
  } catch (err) {
    console.log(err);
  }
}

let hasIntersected = false;
const io = new IntersectionObserver(entries => {
  if (entries[0].isIntersecting && !hasIntersected) {
    hasIntersected = true;
    animateDesktop();
  }
});

const oneClick = document.querySelector('.one-click-container .app-icon');

if (oneClick) {
  io.observe(oneClick);
}

const defaultColors = ['#a864fd', '#29cdff', '#78ff44', '#ff718d', '#fdff6a'];

function createElements(root, elementCount, colors) {
  return Array.from({ length: elementCount }).map((_, index) => {
    const element = document.createElement('div');
    const color = colors[index % colors.length];
    element.style['background-color'] = color; // eslint-disable-line space-infix-ops
    element.style.width = '10px';
    element.style.height = '10px';
    element.style.position = 'absolute';
    root.appendChild(element);
    return element;
  });
}

function randomPhysics(angle, spread, startVelocity, random) {
  const radAngle = angle * (Math.PI / 180);
  const radSpread = spread * (Math.PI / 180);
  return {
    x: 0,
    y: 0,
    wobble: random() * 10,
    velocity: startVelocity * 0.5 + random() * startVelocity,
    angle2D: -radAngle + (0.5 * radSpread - random() * radSpread),
    angle3D: -(Math.PI / 4) + random() * (Math.PI / 2),
    tiltAngle: random() * Math.PI
  };
}

function updateFetti(fetti, progress, decay) {
  /* eslint-disable no-param-reassign */
  fetti.physics.x += Math.cos(fetti.physics.angle2D) * fetti.physics.velocity;
  fetti.physics.y += Math.sin(fetti.physics.angle2D) * fetti.physics.velocity;
  fetti.physics.z += Math.sin(fetti.physics.angle3D) * fetti.physics.velocity;
  fetti.physics.wobble += 0.1;
  fetti.physics.velocity *= decay;
  fetti.physics.y += 3;
  fetti.physics.tiltAngle += 0.1;

  const { x, y, tiltAngle, wobble } = fetti.physics;
  const wobbleX = x + 10 * Math.cos(wobble);
  const wobbleY = y + 10 * Math.sin(wobble);
  const transform = `translate3d(${wobbleX}px, ${wobbleY}px, 0) rotate3d(1, 1, 1, ${tiltAngle}rad)`;

  fetti.element.style.transform = transform;
  fetti.element.style.opacity = 1 - progress;

  /* eslint-enable */
}

function animate(root, fettis, decay) {
  const totalTicks = 200;
  let tick = 0;

  function update() {
    fettis.forEach(fetti => updateFetti(fetti, tick / totalTicks, decay));

    tick += 1;
    if (tick < totalTicks) {
      requestAnimationFrame(update);
    } else {
      fettis.forEach(fetti => {
        if (fetti.element.parentNode === root) {
          return root.removeChild(fetti.element);
        }
      });
    }
  }

  requestAnimationFrame(update);
}

window.confetti = function confetti(
  root,
  {
    angle = 90,
    decay = 0.9,
    spread = 45,
    startVelocity = 45,
    elementCount = 50,
    colors = defaultColors,
    random = Math.random
  } = {}
) {
  const elements = createElements(root, elementCount, colors);
  const fettis = elements.map(element => ({
    element,
    physics: randomPhysics(angle, spread, startVelocity, random)
  }));

  animate(root, fettis, decay);
};
