import React from 'react';
import styled from 'styled-components';
import Container from '../../~reusables/layout/Container';

import cursorIcon from '../../~reusables/assets/cursor-icon.svg';
import appleIcon from '../../~reusables/assets/apple-icon.svg';
import windowsIcon from '../../~reusables/assets/windows-icon.svg';
import BrowserIconSvg from '../../~reusables/atoms/BrowserIconSvg';
import Fade from 'react-reveal/Fade';

import '../../styles/components/convert-your-app-cta.scss';
import { H4, P2 } from '../../~reusables/atoms/Text';

const LandingBenefits = () => {
  return (
    <StyledLandingBenefits>
      <Fade bottom>
        <Container className="landing-benefits">
          <div className="quick-feature-overview alt">
            <ul className="feature-list">
              <li>
                <div
                  className="feature-icon-box"
                  style={{
                    animation: 'cursor-box-icon-animation 5s infinite'
                  }}
                >
                  <img
                    src={cursorIcon}
                    width="24"
                    height="24"
                    className="cursor-icon"
                    alt="Mouse cursor clicking"
                  />
                </div>
                <H4>One-click access to your app</H4>
                <P2>from the start menu, desktop or dock</P2>
              </li>
              <li>
                <div className="feature-icon-box">
                  <div className="browser-container">
                    <BrowserIconSvg />
                  </div>
                </div>
                <H4>No toolbars, no tabs, no distractions</H4>
                <P2>Your web app in a clean, focused user interface</P2>
              </li>
              <li>
                <div className="feature-icon-box">
                  <img
                    src={appleIcon}
                    width="24"
                    height="24"
                    className="apple-icon"
                    alt="apple logo"
                  />
                  <img
                    src={windowsIcon}
                    width="24"
                    height="24"
                    className="windows-icon"
                    alt="windows logo"
                  />
                </div>
                <H4>Windows, Mac, Linux</H4>
                <P2>Installer, auto-updates &amp; code signing included</P2>
              </li>
            </ul>
          </div>
        </Container>
      </Fade>
    </StyledLandingBenefits>
  );
};

const StyledLandingBenefits = styled.section`
  background: ${props => props.theme.colors.lightBackground};
  padding: ${props => props.theme.space[8]} 0;

  .quick-feature-overview {
    margin: ${props => props.theme.space[5]};
  }

  h4 {
    margin: ${props => props.theme.space[3]} 0;
  }

  p {
    color: ${props => props.theme.colors.greys[4]};
  }

  @media only screen and (max-width: ${props => props.theme.breakpoints[0]}) {
    padding: ${props => props.theme.space[8]} 0;
  }
`;

export default LandingBenefits;
