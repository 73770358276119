import React, { useState } from 'react';
import styled from 'styled-components';
import { PrimaryButton } from '../atoms/Button';
import { buildWithAppUrl } from '../../actions/buildWithAppUrl';
import { P2 } from '../atoms/Text';
import { eventTracking } from '../constants/eventTracking';

const CTAWithInput = ({
  lead: { companyName, customIcon, autoIcon },
  track,
  location,
  noShadow
}) => {
  const [appUrl, setAppUrl] = useState('');

  const onFormSubmit = e => {
    e.preventDefault();
    track(eventTracking.submittedURLForm, {
      url: appUrl,
      host: 'leads',
      source: 'build your app cta',
      location
    });
    buildWithAppUrl(appUrl, companyName, customIcon, autoIcon);
  };

  return (
    <StyledCTA onSubmit={onFormSubmit} noShadow={noShadow}>
      <P2>Your Web App's URL:</P2>
      <div>
        <input
          value={appUrl}
          onChange={e => setAppUrl(e.target.value)}
          required
          placeholder="https://..."
        />
        <PrimaryButton className="cta-button">Build your app</PrimaryButton>
      </div>
    </StyledCTA>
  );
};

const StyledCTA = styled.form`
  width: 100%;
  max-width: 420px;

  p {
    color: ${props =>
      props.noShadow ? props.theme.colors.text : props.theme.colors.lightText};
    text-align: left;
    margin-bottom: 0.2rem;
  }

  & > div {
    position: relative;
  }

  button.cta-button {
    position: absolute;
    right: 6px;
    top: 6px;
    font-size: 1.8rem;
    padding: ${props => `${props.theme.space[4]} ${props.theme.space[5]}`};
    width: 140px;
    min-width: 140px;
  }

  input {
    font-size: ${props => props.theme.fontSizes[3]};
    box-shadow: ${props =>
      props.noShadow ? '' : props.theme.shadows.shallowDark};
    transition: all 100ms ease-in-out;
    height: 52px;
    width: 100%;
    background-color: white;
    border: 1px solid ${props => props.theme.colors.greys[6]};
    padding-left: ${props => props.theme.space[5]};
    padding-right: 16rem;
    color: ${props => props.theme.colors.text};
    font-weight: 400;
    border-radius: 4px;
    outline: none;

    &:hover,
    &:focus {
      border-color: ${props => props.theme.colors.text};
    }

    ::placeholder {
      color: ${props => props.theme.colors.greys[5]};
      opacity: 1;
    }

    :-ms-input-placeholder {
      color: ${props => props.theme.colors.greys[5]};
    }

    ::-ms-input-placeholder {
      color: ${props => props.theme.colors.greys[5]};
    }
  }

  @media only screen and (max-width: ${props => props.theme.breakpoints[1]}) {
    width: 100%;
  }

  @media only screen and (max-width: ${props => props.theme.breakpoints[0]}) {
    width: 100%;

    button.cta-button {
      position: static;
      width: 100%;
      margin-top: ${props => props.theme.space[6]};
      box-shadow: ${props =>
        props.noShadow ? '' : props.theme.shadows.shallowDark};
    }

    input {
      padding-right: ${props => props.theme.space[5]};
    }
  }
`;

export default CTAWithInput;
