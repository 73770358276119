// eslint-disable-next-line
!function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on"];analytics.factory=function(t){return function(){var e=Array.prototype.slice.call(arguments);e.unshift(t);analytics.push(e);return analytics}};for(var t=0;t<analytics.methods.length;t++){var e=analytics.methods[t];analytics[e]=analytics.factory(e)}analytics.load=function(t,e){var n=document.createElement("script");n.type="text/javascript";n.async=!0;n.src="https://us-central1-todesktop-prod1.cloudfunctions.net/segProxy/"+t+"/seg.js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(n,a);analytics._loadOptions=e};analytics.SNIPPET_VERSION="4.1.0";
    analytics.load(
        process.env.NODE_ENV === 'production'
        ? 'j9JhAq6oHUh81Bs1W3A0rsY0jIgR4GF0'
        : 'PR6VpkxdEaci1DbXGcxu6uX8wmEG1fRx', 
        {
            All: true
        }
    );
    analytics.page();
}}();
