import React from 'react';
import styled from 'styled-components';
import { SecondaryButton } from '../../~reusables/atoms/Button';
import { MAX_PAGE_WIDTH } from '../../context/ThemeContext';
import { buildWithAppUrl } from '../../actions/buildWithAppUrl';
import CTAWithInput from '../../~reusables/molecules/CTAWithInput';
import { eventTracking } from '../../~reusables/constants/eventTracking';

const LandingNavbar = ({ cta, lead, track }) => {
  const { appUrl, companyName, customIcon, autoIcon, landingPageUrl } = lead;

  return (
    <StyledLandingNavbar className="landing-navbar">
      <div className="handshake-header">
        <img
          onClick={() => (window.location = landingPageUrl)}
          className="icon"
          src={customIcon || autoIcon}
          alt={companyName}
        />
        {(customIcon || autoIcon) && (
          <span
            className="handshake-emoji"
            role="img"
            aria-label="handshake emoji"
          >
            🤝
          </span>
        )}
        <div className="logo-wrap">
          <a href="https://todesktop.com">
            <img
              className="logo"
              src={require('../../images/todesktop-logo-sq.svg')}
              alt="ToDesktop logo"
            />
          </a>
        </div>
      </div>
      <div className="nav-cta">
        {cta === 'build-app' ? (
          appUrl ? (
            <SecondaryButton
              onClick={() => {
                track(eventTracking.submittedURLForm, {
                  url: appUrl,
                  host: 'leads',
                  source: 'build your app cta',
                  location: 'navbar'
                });
                buildWithAppUrl(appUrl, companyName, customIcon, autoIcon);
              }}
            >
              Build {companyName} Now
            </SecondaryButton>
          ) : (
            <div className="cta-with-input">
              <CTAWithInput lead={lead} track={track} location="navbar" />
            </div>
          )
        ) : cta === 'intercom' ? (
          <SecondaryButton className="js-intercom">
            Request demo
          </SecondaryButton>
        ) : (
          ''
        )}
      </div>
    </StyledLandingNavbar>
  );
};

const StyledLandingNavbar = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: ${MAX_PAGE_WIDTH}px;
  margin: 0 auto;
  flex-wrap: wrap;
  padding: ${props => props.theme.space[6]} ${props => props.theme.space[8]};

  &.sticky-shadow {
    box-shadow: ${props => props.theme.shadows.shallowDark};
    -webkit-box-shadow: ${props => props.theme.shadows.shallowDark};
    -moz-box-shadow: ${props => props.theme.shadows.shallowDark};
    background: linear-gradient(105.02deg, #009eff 0%, #0242e4 101.17%);
  }

  .handshake-header {
    display: flex;
    align-items: center;

    .handshake-emoji {
      font-size: ${props => props.theme.fontSizes[6]};
      margin: 0 ${props => props.theme.space[6]};
    }

    img {
      -webkit-filter: drop-shadow(3px 3px 3px rgba(0, 0, 0, 0.1));
      filter: drop-shadow(3px 3px 3px rgba(0, 0, 0, 0.1));
    }

    .icon {
      max-height: 56px;
      float: right;
      display: block;
      cursor: pointer;

      transition: all 200ms ease;

      &:hover {
        transform: translateY(-2px);
      }
    }

    .logo {
      height: 56px;
      display: block;
      float: left;
    }

    a {
      img {
        transition: all 200ms ease;
      }

      &:hover {
        img {
          transform: translateY(-2px);
        }
      }
    }
  }

  @media only screen and (max-width: ${props => props.theme.breakpoints[1]}) {
    justify-content: space-evenly;
    padding: ${props => props.theme.space[5]};
    .nav-cta {
      display: none;
    }
  }

  @media only screen and (max-width: ${props => props.theme.breakpoints[0]}) {
    .handshake-header {
      align-self: flex-start;
      width: 90%;
      .handshake-emoji {
        font-size: ${props => props.theme.fontSizes[5]};
        margin: 0 ${props => props.theme.space[5]};
      }

      .icon {
        max-height: 36px;
      }

      .logo {
        height: 36px;
      }
    }
  }
`;

export default LandingNavbar;
