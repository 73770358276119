import styled from 'styled-components';

const Paragraph = styled.p`
  line-height: ${props => props.theme.lineHeights.copy};
  color: ${props =>
    props.white ? props.theme.colors.lightText : props.theme.colors.text};
`;

export const P1 = styled(Paragraph)`
  font-size: ${props => props.theme.fontSizes[4]};

  @media only screen and (max-width: ${props => props.theme.breakpoints[0]}) {
    font-size: ${props => props.theme.fontSizes[3]};
  }
`;

export const P2 = styled(Paragraph)`
  font-size: ${props => props.theme.fontSizes[3]};

  @media only screen and (max-width: ${props => props.theme.breakpoints[0]}) {
    font-size: ${props => props.theme.fontSizes[2]};
  }
`;

export const P3 = styled(Paragraph)`
  font-size: ${props => props.theme.fontSizes[1]};
`;

export const H1 = styled.h1`
  color: ${props =>
    props.white ? props.theme.colors.lightTitle : props.theme.colors.title};
  line-height: ${props => props.theme.lineHeights.title};
  font-size: ${props => props.theme.fontSizes[7]};
  font-weight: ${props => props.theme.fontWeights[5]};

  @media only screen and (max-width: ${props => props.theme.breakpoints[0]}) {
    font-size: ${props => props.theme.fontSizes[6]};
  }
`;

export const H2 = styled.h2`
  color: ${props =>
    props.white ? props.theme.colors.lightTitle : props.theme.colors.title};
  line-height: ${props => props.theme.lineHeights.title};
  font-size: ${props => props.theme.fontSizes[6]};
  font-weight: ${props => props.theme.fontWeights[5]};

  @media only screen and (max-width: ${props => props.theme.breakpoints[0]}) {
    font-size: ${props => props.theme.fontSizes[5]};
  }
`;

export const H3 = styled.h3`
  color: ${props =>
    props.white ? props.theme.colors.lightTitle : props.theme.colors.title};
  line-height: ${props => props.theme.lineHeights.title};
  font-size: ${props => props.theme.fontSizes[5]};
  font-weight: ${props => props.theme.fontWeights[5]};

  @media only screen and (max-width: ${props => props.theme.breakpoints[0]}) {
    font-size: ${props => props.theme.fontSizes[4]};
  }
`;

export const H4 = styled.h4`
  color: ${props =>
    props.white ? props.theme.colors.lightTitle : props.theme.colors.title};
  line-height: ${props => props.theme.lineHeights.copy};
  font-size: ${props => props.theme.fontSizes[4]};
  font-weight: ${props => props.theme.fontWeights[4]};

  @media only screen and (max-width: ${props => props.theme.breakpoints[0]}) {
    font-size: ${props => props.theme.fontSizes[3]};
  }
`;

export const H5 = styled.h5`
  color: ${props =>
    props.white ? props.theme.colors.lightTitle : props.theme.colors.title};
  line-height: ${props => props.theme.lineHeights.copy};
  font-size: ${props => props.theme.fontSizes[3]};
  font-weight: ${props => props.theme.fontWeights[4]};

  @media only screen and (max-width: ${props => props.theme.breakpoints[0]}) {
    font-size: ${props => props.theme.fontSizes[2]};
  }
`;

export const H6 = styled.h6`
  color: ${props =>
    props.white ? props.theme.colors.lightTitle : props.theme.colors.title};
  line-height: ${props => props.theme.lineHeights.copy};
  font-size: ${props => props.theme.fontSizes[1]};
  font-weight: ${props => props.theme.fontWeights[4]};
  letter-spacing: ${props => props.theme.letterSpacing.tight};
`;
